import { apiClient } from './api';

export interface TeacherWorkHistory {
  id: number;
  year: number;
  desc: string;
  type: number;
}

export interface TeacherWorks {
  url: string;
  title: string;
  desc: string;
  content: {
    id: string;
    name: string;
    url: string;
  };
}

export interface TeacherRecommendations {
  url: string;
  title: string;
  desc: string;
  content: {
    id: string;
    name: string;
    url: string;
  };
}

export interface Teacher {
  userID: string;
  lastName: string;
  firstName: string;
  avatorID?: number;
  avatorURL?: string;
  jobTitle: string;
  organization: string;
  license?: string;
  desc: string;
  links?: {
    homepage: string;
    twitter: string;
    facebook: string;
    instagram: string;
    recruit: string;
  };
  works: TeacherWorks[];
  recommendations: TeacherRecommendations[];
  courseCount: number;
  courseIDs: number[];
  enrolledStudentsCount: number;
  workHistories: TeacherWorkHistory[];
}

export const getTeachers = async (sort?: string): Promise<Teacher[]> => {
  return apiClient.fetchWithoutToken<Teacher[]>('/teachers', {
    sort: sort ?? 'latest',
  });
};

export const getTeachersByQuery = async (query: string): Promise<Teacher[]> => {
  return apiClient.fetchWithoutToken<Teacher[]>(`/teachers?query=${query}`);
};

export const getTeacher = async (id: string): Promise<Teacher> => {
  return apiClient.fetchWithoutToken<Teacher>(`/teachers/${id}`);
};
