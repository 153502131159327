import { createReducer } from '@reduxjs/toolkit';
import { CourseQuizStatus, CourseStatus } from '../../repositories/student';
import {
  studentInitCourseAndForum,
  studentsCancelCourseStatus,
  studentsClearCourseQuiz,
  studentsGetCourseQuizzes,
  studentsGetCourseStatus,
  studentsGetForumStatus,
  studentsRegisterCourseStatus,
  studentsReportCourseStatus,
  studentsReportForumStatus,
  studentsSubmitCourseQuiz,
  CourseStatusWithDetail,
} from './action';

interface StudentState {
  courseStatus: CourseStatus[];
  forumStatus: Record<number, { updatedAt: string }>;
  courseQuestionStatus: Record<number, { updatedAt: string }>;
  courseQuizzesStatus: CourseQuizStatus[];
  courseQuizStatus?: CourseQuizStatus;
  courseInProgress: CourseStatusWithDetail[];
  courseCompleted: CourseStatusWithDetail[];
  loadingCourseStatus: boolean;
  loadingForumStatus: boolean;
}

const initialState: StudentState = {
  loadingCourseStatus: false,
  loadingForumStatus: false,
  courseInProgress: [],
  courseCompleted: [],
  courseStatus: [],
  forumStatus: {},
  courseQuestionStatus: {},
  courseQuizzesStatus: [],
  courseQuizStatus: undefined,
};

export const studentReducer = createReducer(initialState, builder => {
  builder
    .addCase(studentsRegisterCourseStatus.fulfilled, (state, action) => {
      state.courseStatus.push(action.payload);
    })
    .addCase(studentsCancelCourseStatus.fulfilled, (state, action) => {
      state.courseStatus = state.courseStatus.filter(
        cs => cs.courseID !== action.payload,
      );
    })
    .addCase(studentsGetCourseStatus.fulfilled, (state, action) => {
      state.courseStatus = action.payload;
    })
    .addCase(studentsReportCourseStatus.fulfilled, (state, action) => {
      const existingIndex = state.courseStatus.findIndex(
        cs => cs.id === action.payload.id,
      );
      const index =
        existingIndex > -1 ? existingIndex : state.courseStatus.length;
      state.courseStatus[index] = action.payload;
    })
    .addCase(studentsReportForumStatus.fulfilled, (state, action) => {
      if (action.payload.type === 'forum') {
        state.forumStatus[action.payload.forumID] = {
          updatedAt: action.payload.updatedAt,
        };
        return;
      }
      state.courseQuestionStatus[action.payload.forumID] = {
        updatedAt: action.payload.updatedAt,
      };
    })
    .addCase(studentsGetForumStatus.fulfilled, (state, action) => {
      for (const status of action.payload) {
        if (status.type === 'forum') {
          state.forumStatus[status.forumID] = {
            updatedAt: status.updatedAt,
          };
        } else {
          state.courseQuestionStatus[status.forumID] = {
            updatedAt: status.updatedAt,
          };
        }
      }
    })
    .addCase(studentsGetCourseQuizzes.fulfilled, (state, action) => {
      state.courseQuizzesStatus = action.payload;
    })
    .addCase(studentsSubmitCourseQuiz.fulfilled, (state, action) => {
      state.courseQuizStatus = action.payload;
      // courseQuizzesStatusよりよい結果の場合はそちらにも反映する
      const existingIndex = state.courseQuizzesStatus.findIndex(
        status => status.courseID === action.payload.courseID,
      );
      // コースが存在しない場合は単純に追加
      if (existingIndex < 0) {
        state.courseQuizzesStatus.push(action.payload);
        return;
      }
      // 新しい解答の正答が多い場合は新しいものに変更
      if (
        state.courseQuizzesStatus[existingIndex].numberOfCorrectAnswers <=
        action.payload.numberOfCorrectAnswers
      ) {
        state.courseQuizzesStatus[existingIndex] = action.payload;
      }
    })
    .addCase(studentsClearCourseQuiz, state => {
      state.courseQuizStatus = undefined;
    })
    .addCase(studentInitCourseAndForum.pending, state => {
      state.loadingForumStatus = true;
      state.loadingCourseStatus = true;
    })
    .addCase(studentInitCourseAndForum.fulfilled, (state, action) => {
      const {
        relatedCourses,
        courseStatus,
        forumStatus,
        quizStatus,
      } = action.payload;
      state.courseQuizzesStatus = quizStatus;
      const cStatus = courseStatus.reduce(
        (prev, status) => {
          const courseID = status.courseID;
          const course = relatedCourses.find(c => c.id === courseID);
          if (!course) {
            return prev;
          }
          const quiz = quizStatus.find(cqs => cqs.courseID === courseID);
          const total = course.expectedLectures + 1;
          const quizScore = quiz?.pass === true ? 1 : 0;
          const progress =
            (quizScore + status.lectureProgresses.length) / total;
          const result = {
            detail: course,
            status: status,
            progress,
          };
          if (progress >= 1) {
            prev.completed.push(result);
          } else {
            prev.inProgress.push(result);
          }
          return prev;
        },
        {
          inProgress: [] as CourseStatusWithDetail[],
          completed: [] as CourseStatusWithDetail[],
        },
      );
      state.courseCompleted = cStatus.completed;
      state.courseInProgress = cStatus.inProgress;
      // Update forum status
      for (const status of forumStatus) {
        if (status.type === 'forum') {
          state.forumStatus[status.forumID] = {
            updatedAt: status.updatedAt,
          };
        } else {
          state.courseQuestionStatus[status.forumID] = {
            updatedAt: status.updatedAt,
          };
        }
      }
      state.loadingForumStatus = false;
      state.loadingCourseStatus = false;
    })
    .addCase(studentInitCourseAndForum.rejected, state => {
      state.loadingForumStatus = false;
      state.loadingCourseStatus = false;
    });
});
