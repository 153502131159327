import { createReducer } from '@reduxjs/toolkit';
import { ExternalUser } from '../../repositories/user';
import {
  usersGet,
  friendsGet,
  followersGet,
  followingsGet,
  followsFollow,
  followsUnfollow,
} from './actions';

interface FollowsState {
  users: ExternalUser[];
  friends: ExternalUser[];
  followers: ExternalUser[];
  followings: ExternalUser[];
  loadingFriend: boolean;
}

const initialState: FollowsState = {
  users: [],
  friends: [],
  followers: [],
  followings: [],
  loadingFriend: false,
};

export const followsReducer = createReducer(initialState, builder => {
  builder
    .addCase(usersGet.fulfilled, (state, action) => {
      state.users = action.payload;
    })
    .addCase(friendsGet.pending, state => {
      state.loadingFriend = true;
    })
    .addCase(friendsGet.fulfilled, (state, action) => {
      state.friends = action.payload;
      state.loadingFriend = false;
    })
    .addCase(friendsGet.rejected, state => {
      state.loadingFriend = false;
    })
    .addCase(followersGet.fulfilled, (state, action) => {
      state.followers = action.payload;
    })
    .addCase(followingsGet.fulfilled, (state, action) => {
      state.followings = action.payload;
    })
    .addCase(followsFollow.fulfilled, (state, action) => {
      const { friends, followings } = action.payload;
      state.friends = friends;
      state.followings = followings;
    })
    .addCase(followsUnfollow.fulfilled, (state, action) => {
      const { friends, followings } = action.payload;
      state.friends = friends;
      state.followings = followings;
    });
});
