import { createReducer } from '@reduxjs/toolkit';
import { ExternalUser } from '@/repositories/user';
import { Note } from '@/repositories/note';
import { UserBadge } from '@/repositories/badge';
import {
  externalUserFavoriteCreate,
  externalUserFavoriteDelete,
  getExternalUserDetail,
} from './actions';
import { CourseStatusWithDetail } from '@/store/students/action';

interface ExternalUserState {
  user: {
    profile: ExternalUser;
    badges: UserBadge[];
    loadingCourseStatus: boolean;
    courseInProgress: CourseStatusWithDetail[];
    courseCompleted: CourseStatusWithDetail[];
    notes: Note[];
    loadingFriend: boolean;
    friends: ExternalUser[];
    followings: ExternalUser[];
    followers: ExternalUser[];
  };
}

const initExternalUserState: ExternalUserState = {
  user: {
    profile: {
      userID: '',
      firstName: '',
      lastName: '',
      organization: '',
      desc: '',
      avatorID: 0,
      avatorURL: undefined,
    },
    badges: [],
    loadingCourseStatus: false,
    courseInProgress: [],
    courseCompleted: [],
    notes: [],
    loadingFriend: false,
    friends: [],
    followings: [],
    followers: [],
  },
};

export const externalUsersReducer = createReducer(
  initExternalUserState,
  builder => {
    builder
      .addCase(getExternalUserDetail.pending, state => {
        state.user.loadingCourseStatus = true;
        state.user.loadingFriend = true;
      })
      .addCase(getExternalUserDetail.fulfilled, (state, action) => {
        const {
          profile,
          badges,
          notes,
          courseStatuses,
          relatedCourses,
          quizStatus,
        } = action.payload!;
        state.user.profile = { ...profile };
        state.user.badges = [...badges];
        state.user.notes = [...notes];
        const cStatus = courseStatuses.reduce(
          (prev, status) => {
            const courseID = status.courseID;
            const course = relatedCourses.find(c => c.id === courseID);
            if (!course) {
              return prev;
            }

            const quiz = quizStatus.find(cqs => cqs.courseID === courseID);
            const total = course.expectedLectures + 1;
            const quizScore = quiz?.pass === true ? 1 : 0;
            const progress =
              (quizScore + status.lectureProgresses.length) / total;
            const result = {
              detail: course,
              status: status,
              progress,
            };
            if (progress >= 1) {
              prev.completed.push(result);
            } else {
              prev.inProgress.push(result);
            }
            return prev;
          },
          {
            inProgress: [] as CourseStatusWithDetail[],
            completed: [] as CourseStatusWithDetail[],
          },
        );
        state.user.courseCompleted = cStatus.completed;
        state.user.courseInProgress = cStatus.inProgress;

        const { friends, followings, followers } = action.payload!;
        state.user.friends = [...friends];
        state.user.followings = [...followings];
        state.user.followers = [...followers];

        state.user.loadingCourseStatus = false;
        state.user.loadingFriend = false;
      })
      .addCase(externalUserFavoriteCreate.fulfilled, (state, action) => {
        const index = state.user.notes.findIndex(
          f => f.id === action.payload.noteID,
        );
        state.user.notes[index].isMyFavorite = true;
        state.user.notes[index].favoriteCount += 1;
      })
      .addCase(externalUserFavoriteDelete.fulfilled, (state, action) => {
        const index = state.user.notes.findIndex(
          f => f.id === action.payload.noteID,
        );
        state.user.notes[index].isMyFavorite = false;
        state.user.notes[index].favoriteCount -= 1;
      });
  },
);
