import { createAsyncThunk } from '@reduxjs/toolkit';
import { getExternalUser } from '../../repositories/user';
import { endAPILoading, startAPILoading } from '../loading/action';
import { Course, getCourse } from '@/repositories/course';
import { getUserBadges } from '@/repositories/badge';
import {
  getCourseStatusByUserID,
  getCourseQuizStatus,
  CourseQuizStatus,
} from '@/repositories/student';
import { createFavorite, deleteFavorite, getNotes } from '@/repositories/note';
import { getFriends, getFollowers, getFollowings } from '@/repositories/user';

export const getExternalUserDetail = createAsyncThunk(
  'external_users/getExternalUserDetail',
  async (userID: string, thunkAPI) => {
    thunkAPI.dispatch(startAPILoading());
    try {
      const [
        externalUser,
        badges,
        notes,
        courseStatuses,
        friends,
        followings,
        followers,
      ] = await Promise.all([
        getExternalUser(userID),
        getUserBadges({ userID }),
        getNotes({ mine: false, creatorID: userID }),
        getCourseStatusByUserID(userID),
        getFriends({ userID }),
        getFollowings({ userID }),
        getFollowers({ userID }),
      ]);
      const profile = { ...externalUser };

      const relatedCourseIDs = courseStatuses.map(cs => cs.courseID);
      const uniqueCourseIDs = relatedCourseIDs.reduce<number[]>(
        (prev, curr) => {
          if (!prev.includes(curr)) {
            prev.push(curr);
          }
          return prev;
        },
        [],
      );
      let relatedCourses: Course[] = [];
      let quizStatus: CourseQuizStatus[] = [];
      if (uniqueCourseIDs.length) {
        // 取得できなかったコースは除いて取得できたコースだけ返却する
        // see. https://stackoverflow.com/a/46024590
        relatedCourses = await Promise.all(
          uniqueCourseIDs.map(id => getCourse(id).catch(e => e)),
        );
        relatedCourses = relatedCourses.filter(
          course => !(course instanceof Error),
        );

        const statuses = await Promise.all(
          uniqueCourseIDs.map(id => getCourseQuizStatus(id).catch(e => e)),
        );
        quizStatus = statuses.filter(
          status => status !== undefined && !(status instanceof Error),
        ) as CourseQuizStatus[];
      }

      return {
        profile,
        badges,
        courseStatuses,
        relatedCourses,
        quizStatus,
        notes,
        friends,
        followings,
        followers,
      };
    } finally {
      thunkAPI.dispatch(endAPILoading());
    }
  },
);

export const externalUserFavoriteCreate = createAsyncThunk(
  'external_users/createFavorite',
  async (noteID: number, thunkAPI) => {
    thunkAPI.dispatch(startAPILoading());
    try {
      const note = await createFavorite(noteID);
      return note;
    } finally {
      thunkAPI.dispatch(endAPILoading());
    }
  },
);

export const externalUserFavoriteDelete = createAsyncThunk(
  'external_users/deleteFavorite',
  async (noteID: number, thunkAPI) => {
    thunkAPI.dispatch(startAPILoading());
    try {
      const note = await deleteFavorite(noteID);
      return note;
    } finally {
      thunkAPI.dispatch(endAPILoading());
    }
  },
);
