import {
  SUBSCRIPTION_TYPE_REGULAR,
  SUBSCRIPTION_TYPE_TRIAL,
} from '../repositories/user';

interface Subscription {
  active: boolean;
  expireAt?: string;
  canceledAt?: string;
}

export const subscribing = (
  subscription: Subscription | undefined,
): boolean => {
  if (!subscription) {
    return false;
  }
  return !!subscription.active;
};

export const subscriptionStateChanged = (
  subscription: Subscription | undefined,
  state: string,
): boolean => {
  return (
    (subscribing(subscription) && state === SUBSCRIPTION_TYPE_TRIAL) ||
    (!subscribing(subscription) && state === SUBSCRIPTION_TYPE_REGULAR)
  );
};
