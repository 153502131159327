import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { ApplicationState } from '../index';
import {
  cancelCourseStatus,
  CourseQuizAnswer,
  CourseQuizStatus,
  CourseStatus,
  getCourseQuizStatus,
  getCourseStatus,
  getForumStatus,
  registerCourseStatus,
  reportCourseStatus,
  reportForumStatus,
  submitCourseQuizStatus,
} from '../../repositories/student';
import { courseDetailGet, coursesGetAll } from '../courses/action';
import { endAPILoading, startAPILoading } from '../loading/action';
import { Course, getCourse } from '../../repositories/course';
import { authClient } from '../../repositories/auth';

export const studentsGetCourseStatus = createAsyncThunk(
  'students/getCourseStatus',
  async (_, thunkAPI) => {
    thunkAPI.dispatch(startAPILoading());
    try {
      const status = await getCourseStatus();
      const courseIDs = status.map(s => s.courseID);
      thunkAPI.dispatch(coursesGetAll({ ids: courseIDs }));
      thunkAPI.dispatch(studentsGetCourseQuizzes({ courseIDs }));
      return status;
    } finally {
      thunkAPI.dispatch(endAPILoading());
    }
  },
);

export const studentsReportCourseStatus = createAsyncThunk(
  'students/reportCourseStatus',
  async (
    // watchedTill should be in second
    input: {
      courseID: number;
      lectureID: number;
      watchedTill: number;
      completed: boolean;
    },
    thunkAPI,
  ) => {
    thunkAPI.dispatch(startAPILoading());
    const state = thunkAPI.getState() as ApplicationState;
    const existingStatus = state.students.courseStatus.find(
      cs => cs.courseID === input.courseID,
    );
    if (!existingStatus) {
      throw new Error(`CourseID ${input.courseID} does not exist in store`);
    }
    const existingIndex = existingStatus.lectureProgresses.findIndex(
      lp => lp.lectureID === input.lectureID,
    );
    const index =
      existingIndex > -1
        ? existingIndex
        : existingStatus.lectureProgresses.length;
    const progress = [...existingStatus.lectureProgresses];
    progress[index] = {
      courseStatusID: existingStatus.id,
      lectureID: input.lectureID,
      watchedTill: input.watchedTill,
      completed: input.completed,
    };
    try {
      return reportCourseStatus(input.courseID, {
        ...existingStatus,
        lectureProgresses: progress,
      });
    } finally {
      thunkAPI.dispatch(endAPILoading());
    }
  },
);

export const studentsRegisterCourseStatus = createAsyncThunk(
  'students/registerCourseStatus',
  async (input: { courseID: number }, thunkAPI) => {
    thunkAPI.dispatch(startAPILoading());
    try {
      const status = await registerCourseStatus(input.courseID);
      thunkAPI.dispatch(courseDetailGet({ id: input.courseID }));
      return status;
    } finally {
      thunkAPI.dispatch(endAPILoading());
    }
  },
);

export const studentsCancelCourseStatus = createAsyncThunk(
  'students/cancelCourseStatus',
  async (input: { courseID: number }, thunkAPI) => {
    thunkAPI.dispatch(startAPILoading());
    try {
      await cancelCourseStatus(input.courseID);
      return input.courseID;
    } finally {
      thunkAPI.dispatch(endAPILoading());
    }
  },
);

export const studentsReportForumStatus = createAsyncThunk(
  'students/reportForumStatus',
  async (input: { forumID: number; type: 'forum' | 'course' }) => {
    return reportForumStatus(input.forumID, input.type);
  },
);

export const studentsGetForumStatus = createAsyncThunk(
  'students/getGorumStatus',
  async () => {
    return getForumStatus();
  },
);

export const studentsGetCourseQuizzes = createAsyncThunk(
  'students/getCourseQuizzes',
  async (input: { courseIDs: number[] }) => {
    const quizStatus = await Promise.all(
      input.courseIDs.map(id => getCourseQuizStatus(id)),
    );
    return quizStatus.reduce<CourseQuizStatus[]>((prev, curr) => {
      if (curr !== undefined) {
        prev.push(curr);
      }
      return prev;
    }, []);
  },
);

export const studentsSubmitCourseQuiz = createAsyncThunk(
  'students/submitCourseQuiz',
  async (input: { courseID: number; data: CourseQuizAnswer }) => {
    return submitCourseQuizStatus(input.courseID, input.data);
  },
);

export interface CourseStatusWithDetail {
  status: CourseStatus;
  detail: Course;
  progress: number;
}

export const studentInitCourseAndForum = createAsyncThunk(
  'students/initCourseAndForum',
  async () => {
    const [courseStatus, forumStatus] = await Promise.all([
      getCourseStatus(),
      getForumStatus(),
    ]);
    const relatedCourseIDs = courseStatus.map(cs => cs.courseID);
    const uniqueCourseIDs = relatedCourseIDs.reduce<number[]>((prev, curr) => {
      if (!prev.includes(curr)) {
        prev.push(curr);
      }
      return prev;
    }, []);
    let courses: Course[] = [];
    let quizStatus: CourseQuizStatus[] = [];
    if (uniqueCourseIDs.length) {
      courses = await Promise.all(uniqueCourseIDs.map(id => getCourse(id)));
      const status = await Promise.all(
        uniqueCourseIDs.map(id => getCourseQuizStatus(id)),
      );
      quizStatus = status.filter(
        status => status !== undefined,
      ) as CourseQuizStatus[];
    }
    return {
      userID: authClient.authUser?.userID as string,
      courseStatus,
      forumStatus,
      quizStatus,
      relatedCourses: courses,
    };
  },
);

export const studentsClearCourseQuiz = createAction('students/clearCourseQuiz');
