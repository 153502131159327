import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

export const startAPILoadingWithTimeout = createAsyncThunk(
  'loading/apiLoadingStartTimeout',
  async ({ timeout }: { timeout?: number }, thunkAPI) => {
    thunkAPI.dispatch(startAPILoading());
    await new Promise(resolve => setTimeout(resolve, timeout ?? 1000));
    thunkAPI.dispatch(endAPILoading());
  },
);

export const startAPILoading = createAction('loading/apiLoadingStart');

export const endAPILoading = createAction('loading/apiLoadingEnd');
