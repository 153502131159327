import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { ApplicationState } from '../index';
import {
  Course,
  COURSE_ORDER_LATEST,
  findCourses,
  getCourse,
  getCourseWithoutToken,
  getCourseCategories,
  getCourseQuiz,
  getCurriculums,
  getLectures,
  getLecturesWithoutToken,
  getQuarterCourses,
  COURSE_SORT_KINDS,
} from '../../repositories/course';
import { endAPILoading, startAPILoading } from '../loading/action';
import Router from 'next/router';
import { getCourseStatus } from '../../repositories/student';
export const courseCurriculumsGet = createAsyncThunk(
  'courses/getCurriculums',
  async () => {
    return getCurriculums();
  },
);
export const currentCurriculumGet = createAsyncThunk(
  'courses/getCurriculums',
  async () => {
    const today = new Date();
    const curriculums = await getCurriculums();
    const current = curriculums.filter(
      c => new Date(c.startDate) <= today && new Date(c.endDate) > today,
    );
    return current;
  },
);

export const courseCategoriesGet = createAsyncThunk(
  'courses/getCourseCategories',
  async () => {
    return getCourseCategories();
  },
);

export const quarterCoursesGet = createAsyncThunk(
  'courses/getQuarterCourses',
  async (_, thunkAPI) => {
    thunkAPI.dispatch(startAPILoading());
    try {
      const [currentCourses, pastCourses] = await Promise.all([
        getQuarterCourses(true, COURSE_ORDER_LATEST),
        getQuarterCourses(false, COURSE_ORDER_LATEST),
      ]);
      return {
        currentCourses,
        pastCourses,
      };
    } finally {
      thunkAPI.dispatch(endAPILoading());
    }
  },
);

export const quarterCoursesGetWithStatus = createAsyncThunk(
  'courses/getQuarterCoursesWithStatus',
  async (_, thunkAPI) => {
    thunkAPI.dispatch(startAPILoading());
    const state = thunkAPI.getState() as ApplicationState;
    try {
      const [currentCourses, pastCourses, status] = await Promise.all([
        getQuarterCourses(true, state.courses.courseSort),
        getQuarterCourses(false, state.courses.courseSort),
        getCourseStatus(),
      ]);

      const courseStatusIDs = status.map(c => {
        return c.courseID;
      });
      return {
        currentCourses,
        pastCourses,
        courseStatusIDs,
      };
    } finally {
      thunkAPI.dispatch(endAPILoading());
    }
  },
);

interface CourseFindInput {
  query: string;
  categoryID?: number;
  sort?: string;
}

export const coursesFind = createAsyncThunk(
  'courses/findCourses',
  async (input: CourseFindInput, thunkAPI) => {
    thunkAPI.dispatch(startAPILoading());
    try {
      const courses = await findCourses(
        input.query,
        input.categoryID,
        input.sort,
      );
      thunkAPI.dispatch(courseSearched(courses));
    } finally {
      thunkAPI.dispatch(endAPILoading());
    }
  },
);

export const coursesFindWithStatus = createAsyncThunk(
  'courses/findCoursesWithStatus',
  async (input: CourseFindInput, thunkAPI) => {
    thunkAPI.dispatch(startAPILoading());
    try {
      const [courses, status] = await Promise.all([
        findCourses(input.query, input.categoryID, input.sort),
        getCourseStatus(),
      ]);
      const courseStatusIDs = status.map(c => {
        return c.courseID;
      });
      return {
        courses,
        courseStatusIDs,
      };
    } finally {
      thunkAPI.dispatch(endAPILoading());
    }
  },
);

const getExistingCourse = (
  state: ApplicationState,
  id: number,
): Course | undefined => {
  // Look for existing course in memory
  let course = state.courses.courses.find(c => c.id === id);
  if (course) {
    return course;
  }
  course = state.courses.searchedCourses.find(c => c.id === id);
  if (course) {
    return course;
  }
};

export const coursesGetAll = createAsyncThunk(
  'courses/getAll',
  async (input: { ids: number[] }, thunkAPI) => {
    const state = thunkAPI.getState() as ApplicationState;
    thunkAPI.dispatch(startAPILoading());
    // await thunkAPI.dispatch(studentsGetCourseStatus());
    try {
      const courses = await Promise.all(
        input.ids.map(id => {
          const existingCourse = getExistingCourse(state, id);
          if (existingCourse) {
            return existingCourse;
          }
          return getCourse(id);
        }),
      );
      return courses;
    } finally {
      thunkAPI.dispatch(endAPILoading());
    }
  },
);

export const courseDetailGet = createAsyncThunk(
  'courses/getCourseDetail',
  async (input: { id: number }, thunkAPI) => {
    const state = thunkAPI.getState() as ApplicationState;
    let course = getExistingCourse(state, input.id);
    thunkAPI.dispatch(startAPILoading());
    try {
      if (!course) {
        course = await getCourse(input.id);
      }
      const lectures = await getLectures(input.id);
      return {
        course,
        lectures,
      };
    } catch (error: any) {
      if (error?.status === 404) {
        Router.replace('/404');
      }
      throw error;
    } finally {
      thunkAPI.dispatch(endAPILoading());
    }
  },
);

export const courseDetailGetWithoutToken = createAsyncThunk(
  'courses/getCourseDetailWithoutToken',
  async (input: { id: number }, thunkAPI) => {
    const state = thunkAPI.getState() as ApplicationState;
    let course = getExistingCourse(state, input.id);
    thunkAPI.dispatch(startAPILoading());
    try {
      if (!course) {
        course = await getCourseWithoutToken(input.id);
      }
      const lectures = await getLecturesWithoutToken(input.id);
      return {
        course,
        lectures,
      };
    } catch (error: any) {
      if (error?.status === 404) {
        Router.replace('/404');
      }
      throw error;
    } finally {
      thunkAPI.dispatch(endAPILoading());
    }
  },
);

export const courseQuizGet = createAsyncThunk(
  'courses/quizGet',
  async (input: { courseID: number }, thunkAPI) => {
    thunkAPI.dispatch(startAPILoading());
    try {
      return getCourseQuiz(input.courseID);
    } finally {
      thunkAPI.dispatch(endAPILoading());
    }
  },
);

export const courseSearched = createAction<Course[]>('courses/searched');
export const courseSortUpdate = createAction<COURSE_SORT_KINDS>(
  'courses/updateCourseSort',
);
