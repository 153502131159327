import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import Router from 'next/router';
import { ApplicationState } from '..';
import { uploadFile } from '../../repositories/content';
import {
  createComment,
  createForum,
  ForumQuery,
  getComments,
  getForum,
  getForums,
  getRelatedForums,
  RelatedForumQuery,
  updateComment,
  updateForum,
} from '../../repositories/forum';
import { endAPILoading, startAPILoading } from '../loading/action';
import { studentsReportForumStatus } from '../students/action';
import { showSuccessToast } from '../toast/action';

export interface CreateForumInput {
  title: string;
  desc: string;
  files: File[];
  tags: string[];
}

export interface UpdateForumInput {
  id: number;
  deletedContentIDs: number[];
  createdFiles: File[];
  title: string;
  desc: string;
  tags: string[];
}

export const forumCreate = createAsyncThunk(
  'forums/create',
  async (input: CreateForumInput, thunkAPI) => {
    thunkAPI.dispatch(startAPILoading());
    try {
      let contentIDs = [] as number[];
      if (input.files) {
        const uploadedFiles = await Promise.all(
          input.files.map(file => uploadFile(file)),
        );
        contentIDs = uploadedFiles.map(content => content.id);
      }
      const forum = await createForum({ ...input, contentIDs });
      thunkAPI.dispatch(
        studentsReportForumStatus({ forumID: forum.id, type: 'forum' }),
      );
      Router.push(`/dashboard/forums/${forum.id}`);
      thunkAPI.dispatch(
        showSuccessToast({
          title: 'トピックを作成しました',
          desc: 'あなたのトピックはマイページから確認できます。',
        }),
      );
      return forum;
    } finally {
      thunkAPI.dispatch(endAPILoading());
    }
  },
);

export const forumUpdate = createAsyncThunk(
  'forums/update',
  async (input: UpdateForumInput, thunkAPI) => {
    const { forums } = thunkAPI.getState() as ApplicationState;
    const originalForum = forums.forums.find(forum => forum.id === input.id);
    if (!originalForum) {
      throw new Error(`フォーラム ${input.id}のデータが存在しません.`);
    }
    thunkAPI.dispatch(startAPILoading());
    try {
      const uploadedFiles = await Promise.all(
        input.createdFiles.map(file => uploadFile(file)),
      );
      const contentIDs = uploadedFiles.map(content => content.id);
      const newContentIDs = originalForum.contentIDs.filter(
        id => !input.deletedContentIDs.includes(id),
      );

      newContentIDs.push(...contentIDs);
      const updatedForum = {
        ...originalForum,
        title: input.title,
        desc: input.desc,
        tags: input.tags,
        contentIDs: newContentIDs,
      };
      // get forums
      const forum = await updateForum(updatedForum);
      thunkAPI.dispatch(
        studentsReportForumStatus({ forumID: forum.id, type: 'forum' }),
      );
      Router.push(`/dashboard/forums/${forum.id}`);
      thunkAPI.dispatch(
        showSuccessToast({
          title: 'トピックを更新しました',
          desc: 'トピックの内容が更新されています。',
        }),
      );
      return forum;
    } finally {
      thunkAPI.dispatch(endAPILoading());
    }
  },
);

export const forumCancel = createAsyncThunk('forums/cancel', async () => {
  Router.push('/dashboard/forums');
});

export const forumsGetAll = createAsyncThunk(
  'forums/getAll',
  async (input: ForumQuery, thunkAPI) => {
    thunkAPI.dispatch(startAPILoading());
    try {
      const forums = await getForums(input);
      return forums;
    } finally {
      thunkAPI.dispatch(endAPILoading());
    }
  },
);

export const forumsLoadMore = createAsyncThunk(
  'forums/getLoadMore',
  async (input: ForumQuery, thunkAPI) => {
    thunkAPI.dispatch(startAPILoading());
    try {
      const forums = await getForums(input);
      return forums;
    } finally {
      thunkAPI.dispatch(endAPILoading());
    }
  },
);

export const forumsGetRelatedTopics = createAsyncThunk(
  'forums/getRelatedTopics',
  async (input: RelatedForumQuery, thunkAPI) => {
    thunkAPI.dispatch(startAPILoading());
    try {
      const forums = await getRelatedForums(input);
      return forums;
    } finally {
      thunkAPI.dispatch(endAPILoading());
    }
  },
);

export const forumsGetRelatedTopicsLoadMore = createAsyncThunk(
  'forums/getRelatedTopicsLoadMore',
  async (input: RelatedForumQuery, thunkAPI) => {
    thunkAPI.dispatch(startAPILoading());
    try {
      const forums = await getRelatedForums(input);
      return forums;
    } finally {
      thunkAPI.dispatch(endAPILoading());
    }
  },
);

export const forumsUpdateListSort = createAction<'latest' | 'views'>(
  'forums/update',
);

export const forumsGetRelatedComments = createAsyncThunk(
  'forums/getRelatedComments',
  async (input: { forumID: number }, thunkAPI) => {
    thunkAPI.dispatch(startAPILoading());
    try {
      thunkAPI.dispatch(
        studentsReportForumStatus({ forumID: input.forumID, type: 'forum' }),
      );
      return getComments(input.forumID);
    } finally {
      thunkAPI.dispatch(endAPILoading());
    }
  },
);

export const forumsGet = createAsyncThunk(
  'forums/get',
  async (input: { id: number }, thunkAPI) => {
    thunkAPI.dispatch(startAPILoading());
    try {
      const forum = await getForum(input.id);
      thunkAPI.dispatch(
        studentsReportForumStatus({ forumID: forum.id, type: 'forum' }),
      );
      return forum;
    } finally {
      thunkAPI.dispatch(endAPILoading());
    }
  },
);

export interface CreateCommentInput {
  forumID: number;
  desc: string;
  files?: File[];
}

export const commentCreate = createAsyncThunk(
  'comments/create',
  async (input: CreateCommentInput, thunkAPI) => {
    thunkAPI.dispatch(startAPILoading());
    try {
      let contentIDs: number[] = [];
      if (input.files) {
        const uploadedFiles = await Promise.all(
          input.files.map(file => uploadFile(file)),
        );
        contentIDs = uploadedFiles.map(content => content.id);
      }

      thunkAPI.dispatch(
        studentsReportForumStatus({ forumID: input.forumID, type: 'forum' }),
      );

      const comment = await createComment({ ...input, contentIDs });
      return comment;
    } finally {
      thunkAPI.dispatch(endAPILoading());
    }
  },
);

export interface UpdateCommentInput {
  id: number;
  forumID: number;
  desc: string;
  deletedContentIDs: number[];
  createdFiles: File[];
}

export const commentUpdate = createAsyncThunk(
  'comments/update',
  async (input: UpdateCommentInput, thunkAPI) => {
    const { forums } = thunkAPI.getState() as ApplicationState;
    const originalForum = forums.forums.find(
      forum => forum.id === input.forumID,
    );
    if (!originalForum) {
      throw new Error(`フォーラム ${input.forumID}のデータが存在しません.`);
    }
    const originalComment = originalForum.comments.find(
      comment => comment.id === input.id,
    );
    if (!originalComment) {
      throw new Error(`コメント ${input.id}のデータが存在しません.`);
    }
    thunkAPI.dispatch(startAPILoading());
    try {
      const uploadedFiles = await Promise.all(
        input.createdFiles.map(file => uploadFile(file)),
      );
      const contentIDs = uploadedFiles.map(content => content.id);
      const newContentIDs = originalComment.contentIDs.filter(
        id => !input.deletedContentIDs.includes(id),
      );

      newContentIDs.push(...contentIDs);
      const updatedComment = {
        ...originalComment,
        forumID: input.forumID,
        desc: input.desc,
        contentIDs: newContentIDs,
      };
      await updateComment(updatedComment);
      thunkAPI.dispatch(
        showSuccessToast({
          title: 'トピックのコメントを更新しました',
          desc: 'コメントの内容が更新されています。',
        }),
      );
      return updatedComment;
    } finally {
      thunkAPI.dispatch(endAPILoading());
    }
  },
);
