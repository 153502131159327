import { createReducer } from '@reduxjs/toolkit';
import { Course, Lecture } from '../../repositories/course';
import { Note, Tag } from '../../repositories/note';
import { courseDetailGet, quarterCoursesGet } from '../courses/action';
import {
  favoriteCreate,
  favoriteDelete,
  myTagsGet,
  noteCreate,
  noteDelete,
  noteGet,
  noteGetAll,
} from './action';

interface NoteState {
  notes: Note[];
  myNotes: Note[];
  courses: Course[];
  lectures: Lecture[];
  tags: Tag[];
}

const initialState: NoteState = {
  notes: [],
  myNotes: [],
  courses: [],
  lectures: [],
  tags: [],
};

export const notesReducer = createReducer(initialState, builder => {
  builder.addCase(quarterCoursesGet.fulfilled, (state, action) => {
    state.courses = [
      ...action.payload.currentCourses,
      ...action.payload.pastCourses,
    ];
  });
  builder.addCase(courseDetailGet.fulfilled, (state, action) => {
    state.lectures = action.payload.lectures;
  });
  builder.addCase(noteCreate.fulfilled, (state, action) => {
    state.notes.push(action.payload);
  });
  builder.addCase(noteGetAll.fulfilled, (state, action) => {
    if (action.meta.arg.mine) {
      state.myNotes = action.payload;
    }
    if (!action.meta.arg.mine) {
      state.notes = action.payload;
    }
  });
  builder.addCase(noteGet.fulfilled, (state, action) => {
    const existIndex = state.notes.findIndex(f => f.id === action.payload.id);

    if (action.meta.arg.mine) {
      if (existIndex > -1) {
        state.myNotes[existIndex] = action.payload;
        return;
      }
      state.myNotes.push(action.payload);
    }

    if (!action.meta.arg.mine) {
      if (existIndex > -1) {
        state.notes[existIndex] = action.payload;
        return;
      }
      state.notes.push(action.payload);
    }
  });

  builder.addCase(myTagsGet.fulfilled, (state, action) => {
    state.tags = action.payload;
  });
  builder.addCase(noteDelete.fulfilled, (state, action) => {
    delete state.myNotes[
      state.myNotes.findIndex(note => note.id === action.meta.arg)
    ];
  });
  builder.addCase(favoriteCreate.fulfilled, (state, action) => {
    const index = state.notes.findIndex(f => f.id === action.payload.noteID);
    state.notes[index].isMyFavorite = true;
    state.notes[index].favoriteCount += 1;
  });
  builder.addCase(favoriteDelete.fulfilled, (state, action) => {
    const index = state.notes.findIndex(f => f.id === action.payload.noteID);
    state.notes[index].isMyFavorite = false;
    state.notes[index].favoriteCount -= 1;
  });
});
