import { unknownError, errorCodes } from '../constants/errors';

export const getError = (code?: string): { code: string; message: string } => {
  const errorCode = code ?? unknownError;
  if (!errorCodes[errorCode]) {
    return {
      code: unknownError,
      message: errorCodes[unknownError],
    };
  }
  return {
    code: errorCode,
    message: errorCodes[errorCode],
  };
};

export class APIError extends Error {
  public status: number;
  public code: string;

  constructor(msg: string, status: number, code: string) {
    super(msg);
    this.status = status;
    this.code = code;
  }
}
