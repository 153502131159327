import { apiClient } from './api';
export const NOTIFICATION_CATEGORY_ALL = 'all';
export const NOTIFICATION_CATEGORY_COURSE = 'course';
export const NOTIFICATION_CATEGORY_FORUM = 'forum';
export const NOTIFICATION_CATEGORY_ADMIN = 'admin';
export const NOTIFICATION_CATEGORY_COMMUNITY = 'community';
export type NOTIFICATION_CATEGORY_KINDS =
  | typeof NOTIFICATION_CATEGORY_ALL
  | typeof NOTIFICATION_CATEGORY_COURSE
  | typeof NOTIFICATION_CATEGORY_FORUM
  | typeof NOTIFICATION_CATEGORY_ADMIN
  | typeof NOTIFICATION_CATEGORY_COMMUNITY;

export interface Notification {
  id: number;
  key: string;
  userID: string;
  email: string;
  templateID: number;
  title: string;
  message: string;
  metaData: {
    name: string;
  };
  read: boolean;
  type: number;
  typeName: string;
  category: number;
  categoryName: string;
  activeAt: string;
  canceledAt: string;
  status: number;
  emailSentAt: string;
  createdAt: string;
}

export const updateStatus = async (data: {
  notificationIDs: number[];
}): Promise<{ notificationIDs: number[] }> => {
  return await apiClient.post<{ notificationIDs: number[] }>(
    '/notification-status',
    data,
  );
};

export const getNotifications = async (
  category?: number,
): Promise<Notification[]> => {
  if (category === undefined) {
    return apiClient.fetch<Notification[]>('/notifications', {
      includeSent: String(false),
    });
  }
  return apiClient.fetch<Notification[]>('/notifications', {
    includeSent: String(false),
    category: String(category),
  });
};
export const getUnreadNotifications = async (): Promise<Notification[]> => {
  return apiClient.fetch<Notification[]>('/notifications', {
    includeSent: String(false),
    unreadOnly: String(true),
    limit: String(5),
  });
};
