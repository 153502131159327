import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import resouces from './resources.json';

i18n.use(initReactI18next).init({
  resources: resouces,
  fallbackLng: 'en',
  lng: 'ja',
  keySeparator: '.',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
