import { apiClient } from './api';
import { Course } from './course';
import { User } from './user';

export interface GetNote {
  mine: boolean;
  noteID: number;
}

export interface GetNotes {
  mine: boolean;
  creatorID?: string;
}

export interface CreateNote {
  title: string;
  desc: string;
  status: NoteStatus;
  courseID: number;
  lectureID: number;
}

export interface UpdateNote {
  id: number;
  title: string;
  desc: string;
  status: NoteStatus;
  courseID: number;
  lectureID: number;
}

export interface Tag {
  tag: string;
}

export interface NoteFavorite {
  userID: string;
  noteID: number;
}

export interface Note extends CreateNote {
  id: number;
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
  tags: Tag[];
  courseTitle: string;
  lectureTitle: string;
  courseID: number;
  lectureID: number;
  course: Course;
  creator: User;
  isMyFavorite?: boolean;
  favoriteCount: number;
}

export const NOTE_STATUS = {
  DRAFT: 0,
  PUBLIC: 1,
} as const;

export type NoteStatus = typeof NOTE_STATUS[keyof typeof NOTE_STATUS];

export const createNote = async (data: CreateNote): Promise<Note> => {
  return await apiClient.post<Note>('/notes', data);
};

export const updateNote = async (data: UpdateNote): Promise<Note> => {
  const { id, ...params } = data;
  return await apiClient.patch<Note>(`/notes/${id}`, params);
};

export const deleteNote = async (noteID: number): Promise<void> => {
  await apiClient.delete(`/notes/${noteID}`);
};

export const getNotes = async (data: GetNotes): Promise<Note[]> => {
  const queryParams = {} as Record<string, string>;
  queryParams.mine = String(data.mine);
  if (data.creatorID) {
    queryParams.creatorID = data.creatorID;
  }

  return await apiClient.fetch<Note[]>('/notes', queryParams);
};

export const getNote = async (data: GetNote): Promise<Note> => {
  const { noteID, mine } = data;
  return await apiClient.fetch<Note>(`/notes/${noteID}`, {
    mine: String(mine),
  });
};

export const getMyNote = async (noteID: number): Promise<Note> => {
  return await apiClient.fetch<Note>(`/my-notes/${noteID}`);
};

export const getMyTags = async (): Promise<Tag[]> => {
  return await apiClient.fetch<Tag[]>(`/note-tags`);
};

export const createFavorite = async (noteID: number): Promise<NoteFavorite> => {
  return await apiClient.post<NoteFavorite>(`/notes/${noteID}/favorites`, {});
};

export const deleteFavorite = async (noteID: number): Promise<NoteFavorite> => {
  return await apiClient.delete<NoteFavorite>(`/notes/${noteID}/favorites`);
};
