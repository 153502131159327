import { apiClient } from './api';

export interface News {
  id: number;
  title: string;
  desc: string;
  updatedAt: Date;
}

export const getNews = async (): Promise<News[]> => {
  return apiClient.fetch<News[]>('/news');
};
