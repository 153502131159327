import { apiClient } from './api';

export interface Content {
  id: number;
  name: string;
  path: string;
  format: string;
  url: string;
}

export interface DisplayContent {
  id: number;
  name: string;
  url: string;
}

export const uploadFile = async (file: File): Promise<Content> => {
  return apiClient.postFile<Content>('/contents', file);
};

export const getContent = async (id: number): Promise<Content> => {
  return apiClient.fetch<Content>(`/contents/${id}`);
};
