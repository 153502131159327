import { createReducer } from '@reduxjs/toolkit';
import { closeToast, openErrorToast, openSuccessToast } from './action';

interface ToastState {
  type: 'success' | 'error' | 'none';
  title: string;
  desc: string;
}

const initialState: ToastState = {
  type: 'none',
  title: '',
  desc: '',
};

export const toastReducer = createReducer(initialState, builder => {
  builder
    .addCase(openSuccessToast, (state, action) => {
      state.type = 'success';
      state.title = action.payload.title;
      state.desc = action.payload.desc;
    })
    .addCase(openErrorToast, (state, action) => {
      state.type = 'error';
      state.title = '';
      state.desc = action.payload.desc;
    })
    .addCase(closeToast, state => {
      state.type = 'none';
      state.title = '';
      state.desc = '';
    });
});
