import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import 'swiper/swiper.scss';
import 'plyr/dist/plyr.css';
import '../components/slide_show/slide_show_global.scss';
import '../components/notes/note_editor.global.scss';
import '../components/video/video.css';
import '../containers/landing/md.scss';
import './index.scss';

import type { ReactElement } from 'react';
import { AppPropsWithLayout } from '@/types/page';
import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { store } from '@/store';
import TagManager from 'react-gtm-module';
import '@/i18n';
import { withPasswordProtect } from '@storyofams/next-password-protect';

function App({ Component, pageProps }: AppPropsWithLayout): JSX.Element {
  useEffect(() => {
    TagManager.initialize({
      gtmId: process.env.NEXT_PUBLIC_GTM ?? '',
    });
  }, []);

  const getLayout = Component.getLayout || ((page: ReactElement) => page);

  return (
    <Provider store={store}>{getLayout(<Component {...pageProps} />)}</Provider>
  );
}

export default process.env.PASSWORD_PROTECT
  ? (withPasswordProtect(App, {
      loginApiUrl: '/api/login',
    }) as AppPropsWithLayout)
  : App;
