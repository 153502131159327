import { createReducer } from '@reduxjs/toolkit';
import {
  Course,
  CourseCategory,
  CourseQuiz,
  CourseWithLectures,
  COURSE_ORDER_LATEST,
  COURSE_SORT_KINDS,
  Curriculum,
} from '../../repositories/course';
import {
  courseCategoriesGet,
  courseCurriculumsGet,
  courseDetailGet,
  courseDetailGetWithoutToken,
  courseQuizGet,
  courseSearched,
  coursesGetAll,
  courseSortUpdate,
  coursesFindWithStatus,
  quarterCoursesGet,
  quarterCoursesGetWithStatus,
} from './action';

interface CourseState {
  curriculums: Curriculum[];
  courseCategories: CourseCategory[];
  courses: Course[];
  searchedCourses: Course[];
  courseSort: COURSE_SORT_KINDS;
  courseDetail?: CourseWithLectures;
  courseQuiz?: CourseQuiz;
}

const initialState: CourseState = {
  curriculums: [],
  courseCategories: [],
  courses: [],
  searchedCourses: [],
  courseQuiz: undefined,
  courseSort: COURSE_ORDER_LATEST,
};

const sortCourseByID = (c1: Course, c2: Course) => {
  return c2.id - c1.id;
};
const sortCorseByEnrolledStudent = (c1: Course, c2: Course) => {
  return c2.enrolledStudentsCount - c1.enrolledStudentsCount;
};

export const courseReducer = createReducer(initialState, builder => {
  builder
    .addCase(courseCurriculumsGet.fulfilled, (state, action) => {
      state.curriculums = action.payload;
    })
    .addCase(courseCategoriesGet.fulfilled, (state, action) => {
      state.courseCategories = action.payload;
    })
    .addCase(quarterCoursesGet.fulfilled, (state, action) => {
      state.courses = action.payload.currentCourses;
      state.courses.push(...action.payload.pastCourses);
    })
    .addCase(quarterCoursesGetWithStatus.fulfilled, (state, action) => {
      state.courses = action.payload.currentCourses.map(c => {
        if (action.payload.courseStatusIDs.includes(c.id)) {
          return {
            isEnrolled: true,
            ...c,
          };
        }
        return {
          isEnrolled: false,
          ...c,
        };
      });
      const pastCourses = action.payload.pastCourses.map(c => {
        if (action.payload.courseStatusIDs.includes(c.id)) {
          return {
            isEnrolled: true,
            ...c,
          };
        }
        return {
          isEnrolled: false,
          ...c,
        };
      });
      state.courses.push(...pastCourses);
    })
    .addCase(coursesGetAll.fulfilled, (state, action) => {
      for (const course of action.payload) {
        const existingIndex = state.courses.findIndex(c => course.id === c.id);
        const index = existingIndex > -1 ? existingIndex : state.courses.length;
        state.courses[index] = course;
      }
    })
    .addCase(courseSearched, (state, action) => {
      state.searchedCourses = action.payload;
    })
    .addCase(courseDetailGet.fulfilled, (state, action) => {
      state.courseDetail = {
        ...action.payload.course,
        lectures: action.payload.lectures,
      };
    })
    .addCase(courseDetailGetWithoutToken.fulfilled, (state, action) => {
      state.courseDetail = {
        ...action.payload.course,
        lectures: action.payload.lectures,
      };
    })
    .addCase(courseQuizGet.fulfilled, (state, action) => {
      state.courseQuiz = action.payload;
    })
    .addCase(courseSortUpdate, (state, action) => {
      state.courseSort = action.payload;
      const copiedCourse = state.courses.slice();
      const copiedSearchedCourse = state.searchedCourses.slice();
      if (action.payload === COURSE_ORDER_LATEST) {
        copiedCourse.sort(sortCourseByID);
        copiedSearchedCourse.sort(sortCourseByID);
      } else {
        copiedCourse.sort(sortCorseByEnrolledStudent);
        copiedSearchedCourse.sort(sortCorseByEnrolledStudent);
      }
      state.courses = copiedCourse;
      state.searchedCourses = copiedSearchedCourse;
    })
    .addCase(coursesFindWithStatus.fulfilled, (state, action) => {
      state.searchedCourses = action.payload.courses.map(c => {
        return {
          isEnrolled: action.payload.courseStatusIDs.includes(c.id),
          ...c,
        };
      });
    });
});
