import { createReducer } from '@reduxjs/toolkit';

import {
  Notification,
  NOTIFICATION_CATEGORY_KINDS,
  NOTIFICATION_CATEGORY_ALL,
} from '../../repositories/notification';
import {
  notificationsGet,
  unreadNotificationsGet,
  notificationRead,
  unreadNotificationRead,
  notificationsGetByCategory,
  notificationsUpdateListFilter,
} from './actions';
import { getNotificationSettings, updateNotificationSettings } from './actions';

interface NotificationsState {
  unreadNotifications: Notification[];
  notifications: Notification[];
  listFilterKey: NOTIFICATION_CATEGORY_KINDS;
  notificationSettings: {
    disabledNotifications: string[];
  };
}

const initialState: NotificationsState = {
  unreadNotifications: [],
  notifications: [],
  listFilterKey: NOTIFICATION_CATEGORY_ALL,
  notificationSettings: {
    disabledNotifications: [],
  },
};

export const notificationsReducer = createReducer(initialState, builder => {
  builder

    .addCase(notificationRead.fulfilled, (state, action) => {
      const notification = state.notifications.find(
        n => n.id === action.meta.arg.notificationIDs[0],
      );
      if (notification) {
        notification.read = true;
      }
      state.unreadNotifications = state.unreadNotifications.filter(
        n => n.id !== action.meta.arg.notificationIDs[0],
      );
    })
    .addCase(unreadNotificationRead.fulfilled, (state, action) => {
      state.unreadNotifications = state.unreadNotifications.filter(
        n => n.id !== action.meta.arg.notificationIDs[0],
      );
    })
    .addCase(unreadNotificationsGet.fulfilled, (state, action) => {
      state.unreadNotifications = action.payload;
    })
    .addCase(notificationsGetByCategory.fulfilled, (state, action) => {
      state.notifications = action.payload;
    })
    .addCase(notificationsGet.fulfilled, (state, action) => {
      state.notifications = action.payload;
    })
    .addCase(notificationsUpdateListFilter, (state, action) => {
      state.listFilterKey = action.payload;
    })
    .addCase(getNotificationSettings.fulfilled, (state, action) => {
      state.notificationSettings.disabledNotifications =
        action.payload.disabledNotifications;
    })
    .addCase(updateNotificationSettings.fulfilled, (state, action) => {
      state.notificationSettings.disabledNotifications =
        action.payload.disabledNotifications;
    });
});
