import { dayjs } from '@/utils/time';
import { apiClient } from './api';
import { authClient } from './auth';

export interface User {
  userID: string;
  email: string | undefined;
  lastName: string;
  firstName: string;
  organization?: string;
  desc?: string;
  linkFacebook?: string;
  linkTwitter?: string;
  linkInstagram?: string;
  avatorID?: number;
  avatorURL?: string;
  avator?: {
    url: string;
  };
  newsReadAt: string;
  subscription?: {
    active: boolean;
    expireAt?: string;
    canceledAt?: string;
  };
}

export type ExternalUser = Pick<
  User,
  | 'userID'
  | 'lastName'
  | 'firstName'
  | 'organization'
  | 'desc'
  | 'linkFacebook'
  | 'linkTwitter'
  | 'linkInstagram'
  | 'avatorID'
  | 'avatorURL'
>;

export interface ExtendedUser extends User {
  notification: {
    subscriptionHiddenUntil?: number;
  };
}

export interface NotiSubscription {
  closedUntil: number;
}

export const SUBSCRIPTION_TYPE_REGULAR = 'regular';
export const SUBSCRIPTION_TYPE_TRIAL = 'trial';
const SUBSCRIPTION_CLOSED_KEY = 'SubscriptionClosedKey';

export const getUserInfo = async (): Promise<ExtendedUser> => {
  const user = await apiClient.fetch<User>('/userinfo');
  const subscriptionNoti = getSubscriptionClosed();
  return {
    ...user,
    notification: { subscriptionHiddenUntil: subscriptionNoti?.closedUntil },
  };
};

export const getUsers = async (
  query: Record<string, string>,
): Promise<User[]> => {
  return apiClient.fetch<User[]>('/users', query);
};

export const getExternalUser = async (
  userID: string,
): Promise<ExternalUser> => {
  return apiClient.fetch<ExternalUser>(`/users/${userID}`);
};

export const createUser = async (user: User): Promise<User> => {
  return apiClient.post<User>('/users', user);
};

export const updateUser = async (user: User): Promise<User> => {
  return apiClient.put<User>(`/users/${user.userID}`, user);
};

export const purchaseSubscription = async (): Promise<User> => {
  const userID = authClient.authUser?.userID;
  if (!userID) {
    throw new Error('ログイン情報が見つかりません');
  }
  return apiClient.post<User>(`/users/${userID}/subscription`, {});
};

export const updateSubscription = async (): Promise<User> => {
  const userID = authClient.authUser?.userID;
  if (!userID) {
    throw new Error('ログイン情報が見つかりません');
  }
  return apiClient.patch<User>(`/users/${userID}/subscription`, {});
};

export const cancelSubscription = async (): Promise<User> => {
  const userID = authClient.authUser?.userID;
  if (!userID) {
    throw new Error('ログイン情報が見つかりません');
  }
  return apiClient.delete<User>(`/users/${userID}/subscription`);
};

export const deleteSubscriptionClosed = async (): Promise<void> => {
  typeof window !== 'undefined' &&
    localStorage.removeItem(SUBSCRIPTION_CLOSED_KEY);
};

export const saveSubscriptionClosed = async (): Promise<NotiSubscription> => {
  // 12hrs
  const closedUntil = Date.now() + 12 * 60 * 60 * 1000;
  const data = {
    closedUntil,
  };
  typeof window !== 'undefined' &&
    localStorage.setItem(SUBSCRIPTION_CLOSED_KEY, JSON.stringify(data));

  return data;
};

export const getSubscriptionClosed = (): NotiSubscription | undefined => {
  const rawData =
    typeof window !== 'undefined' &&
    localStorage.getItem(SUBSCRIPTION_CLOSED_KEY);
  if (!rawData) {
    return undefined;
  }
  const data = JSON.parse(rawData) as NotiSubscription;
  if (dayjs(Date.now()).isAfter(dayjs(data.closedUntil))) {
    typeof window !== 'undefined' &&
      localStorage.removeItem(SUBSCRIPTION_CLOSED_KEY);
    return undefined;
  }
  return data;
};

export const getFriends = async (
  params?: Record<string, any>,
): Promise<ExternalUser[]> => {
  return apiClient.fetch<ExternalUser[]>('/follows', {
    ...params,
    type: 'friends',
  });
};

export const getFollowers = async (
  params?: Record<string, any>,
): Promise<ExternalUser[]> => {
  return apiClient.fetch<ExternalUser[]>(`/follows`, {
    ...params,
    type: 'followers',
  });
};

export const getFollowings = async (
  params?: Record<string, any>,
): Promise<ExternalUser[]> => {
  return apiClient.fetch<ExternalUser[]>('/follows', {
    ...params,
    type: 'followings',
  });
};

export const follow = async (user: ExternalUser): Promise<ExternalUser[]> => {
  return apiClient.post<ExternalUser[]>('/follows', {
    method: 'follow',
    followID: user.userID,
  });
};

export const unfollow = async (user: ExternalUser): Promise<ExternalUser[]> => {
  return apiClient.post<ExternalUser[]>('/follows', {
    method: 'unfollow',
    followID: user.userID,
  });
};
