import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getUsers,
  getFriends,
  getFollowers,
  getFollowings,
  follow,
  unfollow,
  ExternalUser,
} from '../../repositories/user';
import { endAPILoading, startAPILoading } from '../loading/action';

export const usersGet = createAsyncThunk(
  'follows/usersGet',
  async (input: { q: string }, thunkAPI) => {
    thunkAPI.dispatch(startAPILoading());
    try {
      const users = await getUsers(input);
      return users;
    } finally {
      thunkAPI.dispatch(endAPILoading());
    }
  },
);

export const friendsGet = createAsyncThunk(
  'follows/friendsGet',
  async (_, thunkAPI) => {
    thunkAPI.dispatch(startAPILoading());
    try {
      return await getFriends();
    } finally {
      thunkAPI.dispatch(endAPILoading());
    }
  },
);

export const followersGet = createAsyncThunk(
  'follows/followersGet',
  async (_, thunkAPI) => {
    thunkAPI.dispatch(startAPILoading());
    try {
      return await getFollowers();
    } finally {
      thunkAPI.dispatch(endAPILoading());
    }
  },
);

export const followingsGet = createAsyncThunk(
  'follows/followingsGet',
  async (_, thunkAPI) => {
    thunkAPI.dispatch(startAPILoading());
    try {
      return await getFollowings();
    } finally {
      thunkAPI.dispatch(endAPILoading());
    }
  },
);

export const followsFollow = createAsyncThunk(
  'follows/follow',
  async (user: ExternalUser, thunkAPI) => {
    thunkAPI.dispatch(startAPILoading());
    try {
      await follow(user);
      const friends = await getFriends();
      const followings = await getFollowings();
      return { friends, followings };
    } finally {
      thunkAPI.dispatch(endAPILoading());
    }
  },
);

export const followsUnfollow = createAsyncThunk(
  'follows/unfollow',
  async (user: ExternalUser, thunkAPI) => {
    thunkAPI.dispatch(startAPILoading());
    try {
      await unfollow(user);
      const friends = await getFriends();
      const followings = await getFollowings();
      return { friends, followings };
    } finally {
      thunkAPI.dispatch(endAPILoading());
    }
  },
);
