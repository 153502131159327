import { apiClient } from './api';

type Notification = 'admin' | 'course' | 'activity';
type NotificationType = 0 | 1 | 2;

interface GetNotificationSettingsResponse {
  userID: string;
  disabledNotifications: Notification[];
  disableNotificationTypes: NotificationType[];
}

interface UpdateNotificationSettingsResponse {
  userID: string;
  disabledNotifications: Notification[];
  disableNotificationTypes: NotificationType[];
}

export const getNotificationSettings = async (): Promise<
  GetNotificationSettingsResponse
> => {
  return await apiClient.fetch('/notification-settings');
};

export const updateNotificationSettings = async (
  data: any,
): Promise<UpdateNotificationSettingsResponse> => {
  return await apiClient.post('/notification-settings', data);
};
