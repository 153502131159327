import { ForgeToken } from '@/repositories/forge';
import { createReducer } from '@reduxjs/toolkit';
import { forgeTokenGet } from './action';

interface ForgeState {
  token: ForgeToken | null;
}

const initialState: ForgeState = {
  token: null,
};

export const forgeReducer = createReducer(initialState, builder => {
  builder.addCase(forgeTokenGet.fulfilled, (state, action) => {
    state.token = action.payload.token;
  });
});
