import { apiClient } from './api';
import { authClient } from './auth';
import { DisplayContent } from './content';
import { User } from './user';

export interface CreateCourseQuestion {
  title: string;
  desc: string;
  contentIDs: number[];
  courseID: number;
  lectureID?: number;
  tags: string[];
}

export interface CreateComment {
  forumID: number;
  desc: string;
  contentIDs: number[];
}

export interface Comment extends CreateComment {
  id: number;
  createdAt: string;
  creator: User;
  contents: DisplayContent[];
}

export interface CourseQuestion extends CreateCourseQuestion {
  id: number;
  courseTitle: string;
  lectureTitle?: string;
  createdAt: string;
  updatedAt: string;
  contents: DisplayContent[];
  comments: Comment[];
  creator: User;
  viewCount: number;
  numberOfComments: number;
}

export const createCourseQuestion = async (
  data: CreateCourseQuestion,
): Promise<CourseQuestion> => {
  const forum = await apiClient.post<CourseQuestion>('/course-questions', data);
  if (forum.comments) {
    return forum;
  }
  return {
    ...forum,
    comments: [],
  };
};

export const updateCourseQuestion = async (
  data: CourseQuestion,
): Promise<CourseQuestion> => {
  return apiClient.put<CourseQuestion>(`/course-questions/${data.id}`, data);
};

export interface CourseQuestionQuery {
  sort?: string;
  query?: string;
  limit?: number;
  offset?: number;
}

export const getCourseQuestions = async (
  courseID: number,
  data?: CourseQuestionQuery,
): Promise<CourseQuestion[]> => {
  let params = { courseID: String(courseID) };
  if (data) {
    params = { ...params, ...data };
  }
  const forums = await apiClient.fetch<CourseQuestion[]>(
    '/course-questions',
    params,
  );
  return forums.map(forum => {
    if (forum.comments) {
      return forum;
    }
    return {
      ...forum,
      comments: [],
    };
  });
};

export interface RelatedCourseQuestionQuery {
  offset: number;
  limit: number;
  type: 'forum' | 'comment';
}

export const getRelatedCourseQuestions = async (
  input: RelatedCourseQuestionQuery,
): Promise<CourseQuestion[]> => {
  const userID = authClient.authUser?.userID;
  if (!userID) {
    throw new Error('ログイン情報が見つかりません');
  }
  const key = input.type === 'forum' ? 'createdBy' : 'commentedBy';
  const query: Record<string, string> = {
    offset: String(input.offset),
    limit: String(input.limit),
    [key]: userID,
  };
  const forums = await apiClient.fetch<CourseQuestion[]>(
    `/course-questions`,
    query,
  );
  return forums.map(forum => {
    if (forum.comments) {
      return forum;
    }
    return {
      ...forum,
      comments: [],
    };
  });
};

export const getCourseQuestion = async (
  forumID: number,
): Promise<CourseQuestion> => {
  const forum = await apiClient.fetch<CourseQuestion>(
    `/course-questions/${forumID}`,
  );
  const comments = await apiClient.fetch<Comment[]>(
    `/course-questions/${forumID}/comments`,
  );
  return {
    ...forum,
    comments,
  };
};

export const getCourseQuestionComments = async (
  forumID: number,
): Promise<Comment[]> => {
  return apiClient.fetch<Comment[]>(`/course-questions/${forumID}/comments`);
};

export const createCourseQuestionComment = async (
  data: CreateComment,
): Promise<Comment> => {
  return apiClient.post(`/course-questions/${data.forumID}/comments`, data);
};

export const updateCourseQuestionComment = async (
  data: Comment,
): Promise<Comment> => {
  return apiClient.put(
    `/course-questions/${data.forumID}/comments/${data.id}`,
    data,
  );
};
