import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/ja';

dayjs.locale('ja');
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Asia/Tokyo');

export { dayjs };

export const formatTime = (serverTime: string): string =>
  dayjs(serverTime).format('YYYY/MM/DD HH:mm');

export const formatDate = (serverTime: string): string =>
  dayjs(serverTime).tz().format('YYYY/MM/DD');

export const convertToMinutes = (seconds: number): string => {
  return `${('0' + Math.floor(seconds / 60)).slice(-2)}:${(
    '0' + Math.floor(seconds % 60)
  ).slice(-2)}`;
};
