import { apiClient } from './api';

export type ForgeToken = {
  token_type: string;
  expires_in: string;
  access_token: string;
  refresh_token: string;
};

export const getForgeToken = async (): Promise<ForgeToken> => {
  return await apiClient.fetch<ForgeToken>('/forge/token');
};
