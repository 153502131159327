import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { setTimeout } from 'timers';

let timer: NodeJS.Timeout | undefined;

const clearTimer = () => {
  if (timer) {
    clearTimeout(timer);
  }
};

export const showSuccessToast = createAsyncThunk(
  'toast/success',
  async (input: { title: string; desc: string }, thunkAPI) => {
    thunkAPI.dispatch(openSuccessToast(input));
    clearTimer();
    timer = setTimeout(() => {
      thunkAPI.dispatch(closeToast());
    }, 3000);
    return input;
  },
);

export const showErrorToast = createAsyncThunk(
  'toast/error',
  async (input: { desc: string }, thunkAPI) => {
    thunkAPI.dispatch(openErrorToast(input));
    clearTimer();
    setTimeout(() => {
      thunkAPI.dispatch(closeToast());
    }, 3000);
    return input;
  },
);

export const openSuccessToast = createAction<{ title: string; desc: string }>(
  'toast/openSuccess',
);
export const openErrorToast = createAction<{ desc: string }>('toast/openError');

export const closeToast = createAction('toast/close', () => {
  clearTimer();
  return { payload: '' };
});
