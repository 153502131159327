import { createReducer } from '@reduxjs/toolkit';
import { Teacher } from '../../repositories/teacher';
import { teacherGet, teachersGet, teachersGetByQuery } from './actions';

interface UserState {
  teachers: Teacher[];
}

const initialState: UserState = {
  teachers: [],
};

export const teachersReducer = createReducer(initialState, builder => {
  builder
    .addCase(teacherGet.fulfilled, (state, action) => {
      const existingIndex = state.teachers.findIndex(
        t => t.userID === action.payload.userID,
      );
      const index = existingIndex > -1 ? existingIndex : state.teachers.length;
      state.teachers[index] = action.payload;
    })
    .addCase(teachersGetByQuery.fulfilled, (state, action) => {
      state.teachers = action.payload;
    })
    .addCase(teachersGet.fulfilled, (state, action) => {
      state.teachers = action.payload;
    });
});
