import { createAction, createAsyncThunk, Dispatch } from '@reduxjs/toolkit';
import Router from 'next/router';
import { dayjs } from '@/utils/time';
import { ApplicationState } from '..';
import {
  AUTH_STATE_SIGNED_IN,
  REGISTER_STATE_REGISTERED,
} from '../../constants/auth_states';
import { AuthUser, authClient } from '../../repositories/auth';
import { uploadFile } from '../../repositories/content';
import { APIError } from '../../repositories/errors';
import {
  cancelSubscription,
  createUser,
  getUserInfo,
  purchaseSubscription,
  saveSubscriptionClosed,
  updateSubscription,
  updateUser,
  User,
} from '../../repositories/user';
import { hasAuthInfo, hasLoginInfo } from '../../utils/auth';
import { subscribing, subscriptionStateChanged } from '../../utils/user';
import { endAPILoading, startAPILoading } from '../loading/action';
import { showSuccessToast, showErrorToast } from '../toast/action';
import { updateNotificationSettings } from '@/repositories/notification-setting';
import { getNotificationSettings } from '@/store/notifications/actions';

const getPath = () => `${window.location.origin}/email-signin`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const authUserListener = (dispatch: Dispatch<any>): void => {
  authClient.onAuthChange(user => {
    if (user) {
      dispatch(updateAuthUser(user));
      return;
    }
  });
  authClient.onRefreshToken(token => {
    dispatch(updateToken(token));
  });
};

export const signin = createAsyncThunk(
  'user/signin',
  async (
    input: { email?: string; providerName?: 'google' | 'twitter' | 'facebook' },
    thunkAPI,
  ) => {
    thunkAPI.dispatch(startAPILoading());
    try {
      if (input.email) {
        await authClient.signinWithEmail(input.email, getPath());
        Router.push('/email-sent');
      } else if (input.providerName) {
        await authClient.signinWithPopup(input.providerName);
        Router.push('/dashboard/mypage');
      }
    } finally {
      thunkAPI.dispatch(endAPILoading());
    }
  },
);

export const signinWithEmail = createAsyncThunk(
  'user/signinWithEmail',
  async (input: { path: string }, thunkAPI) => {
    thunkAPI.dispatch(startAPILoading());
    let authUser: AuthUser;
    try {
      authUser = await authClient.signinWithEmailLink(input.path);
      thunkAPI.dispatch(updateAuthUser(authUser));
    } catch (error) {
      thunkAPI.dispatch(endAPILoading());
      throw error;
    }
    try {
      const existingUser = await getUserInfo();
      thunkAPI.dispatch(updateSelf(existingUser));
      Router.replace('/dashboard/mypage');
      return existingUser;
    } catch (error) {
      if (error instanceof APIError) {
        if (error.status === 404) {
          Router.replace('/setup');
          return;
        }
      }
      throw error;
    } finally {
      thunkAPI.dispatch(endAPILoading());
    }
  },
);

export const signinWithSocialAccount = createAsyncThunk(
  'user/signinWithSocialAccount',
  async (
    input: { providerName: 'google' | 'twitter' | 'facebook' },
    thunkAPI,
  ) => {
    thunkAPI.dispatch(startAPILoading());

    let authUser: AuthUser;
    try {
      authUser = await authClient.signinWithPopup(input.providerName);
      thunkAPI.dispatch(updateAuthUser(authUser));
    } catch (error) {
      thunkAPI.dispatch(endAPILoading());
      throw error;
    }
    try {
      const existingUser = await getUserInfo();
      thunkAPI.dispatch(updateSelf(existingUser));
      Router.replace('/dashboard/mypage');
      return existingUser;
    } catch (error) {
      if (error instanceof APIError) {
        if (error.status === 404) {
          Router.replace('/setup');
          return;
        }
      }
      throw error;
    } finally {
      thunkAPI.dispatch(endAPILoading());
    }
  },
);

export const linkSocialAccount = createAsyncThunk(
  'user/linkSocialAccount',
  async (
    input: {
      providerName: 'google' | 'twitter' | 'facebook';
      callback: () => void;
    },
    thunkAPI,
  ) => {
    const name =
      input.providerName[0].toUpperCase() + input.providerName.slice(1);
    thunkAPI.dispatch(startAPILoading());
    try {
      await authClient.linkWithPopup(input.providerName);
      input.callback();
      thunkAPI.dispatch(
        showSuccessToast({
          title: '',
          desc: `${name}ログインを有効にしました`,
        }),
      );
    } catch (e) {
      thunkAPI.dispatch(
        showErrorToast({
          desc: `${name}ログインを有効にできませんでした`,
        }),
      );
      console.error(e);
    } finally {
      thunkAPI.dispatch(endAPILoading());
    }
  },
);

export const unlinkSocialAccount = createAsyncThunk(
  'user/linkSocialAccount',
  async (
    input: {
      providerName: 'google' | 'twitter' | 'facebook';
      callback: () => void;
    },
    thunkAPI,
  ) => {
    thunkAPI.dispatch(startAPILoading());
    try {
      await authClient.unlinkWithPopup(input.providerName);
      input.callback();
      const name =
        input.providerName[0].toUpperCase() + input.providerName.slice(1);
      thunkAPI.dispatch(
        showSuccessToast({
          title: '',
          desc: `${name}ログインを解除しました`,
        }),
      );
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      thunkAPI.dispatch(endAPILoading());
    }
  },
);

export const registerUser = createAsyncThunk(
  'user/registerUser',
  async (input: User & { avatorFile: File | undefined }, thunkAPI) => {
    thunkAPI.dispatch(startAPILoading());
    try {
      const { avatorFile, ...updatingUser } = input;
      let createdUser = await createUser(updatingUser);
      if (avatorFile) {
        const content = await uploadFile(avatorFile);
        updatingUser.avatorID = content.id;
        updatingUser.avatorURL = content.url;
        createdUser = await updateUser(updatingUser);
      }
      thunkAPI.dispatch(updateSelf(createdUser));
      Router.push('/dashboard/mypage');
    } catch (error) {
      if (error instanceof APIError) {
        if (error.message.includes('already exist')) {
          const existingUser = await getUserInfo();
          thunkAPI.dispatch(updateSelf(existingUser));
          Router.replace('/dashboard/mypage');
          return;
        }
      }
      throw error;
    } finally {
      thunkAPI.dispatch(endAPILoading());
    }
  },
);

export const updateUserInfo = createAsyncThunk(
  'user/updateUserInfo',
  async (
    input: User & { avatorFile: File | undefined } & {
      disabledNotifications: string[];
    },
    thunkAPI,
  ) => {
    // Check if the info is different
    const { users, notifications } = thunkAPI.getState() as ApplicationState;
    const profileChanged =
      users.self.firstName !== input.firstName ||
      users.self.lastName !== input.lastName ||
      users.self.email !== input.email ||
      users.self.desc !== input.desc ||
      users.self.organization !== input.organization ||
      users.self.linkFacebook !== input.linkFacebook ||
      users.self.linkTwitter !== input.linkTwitter ||
      users.self.linkInstagram !== input.linkInstagram ||
      input.avatorFile !== undefined;

    const beforeDisabledNotifications = JSON.stringify(
      notifications.notificationSettings.disabledNotifications.slice().sort(),
    );
    const afterDisabledNotifications = JSON.stringify(
      input.disabledNotifications.slice().sort(),
    );
    const disabledNotificationsChanged =
      beforeDisabledNotifications !== afterDisabledNotifications;

    if (!profileChanged && !disabledNotificationsChanged) {
      Router.push('/dashboard/mypage');
      return;
    }
    thunkAPI.dispatch(startAPILoading());
    try {
      if (profileChanged) {
        const { avatorFile, ...updatingUser } = input;
        if (avatorFile) {
          const content = await uploadFile(avatorFile);
          updatingUser.avatorID = content.id;
          updatingUser.avatorURL = content.url;
        }
        await updateUser(updatingUser);
        thunkAPI.dispatch(getSelf());
      }

      if (disabledNotificationsChanged) {
        await updateNotificationSettings({
          disabledNotifications: input.disabledNotifications,
        });
        thunkAPI.dispatch(getNotificationSettings());
      }

      thunkAPI.dispatch(
        showSuccessToast({
          title: '設定の更新',
          desc: 'ユーザ情報の更新が完了いたしました。',
        }),
      );
      Router.push('/dashboard/mypage');
    } finally {
      thunkAPI.dispatch(endAPILoading());
    }
  },
);

export const updateLoginStatus = createAsyncThunk(
  'user/updateLoginStatus',
  async (_, thunkAPI) => {
    const { users: userState } = thunkAPI.getState() as ApplicationState;
    // if userstate is not zero, do nothing
    if (
      userState.login.authorized === AUTH_STATE_SIGNED_IN &&
      userState.login.registered === REGISTER_STATE_REGISTERED
    ) {
      return;
    }
    if (!hasAuthInfo(userState) && !authClient.authUser) {
      Router.replace('/signin');
      return;
    }
    if (authClient.authUser) {
      thunkAPI.dispatch(updateAuthUser(authClient.authUser));
    }
    if (!hasLoginInfo(userState)) {
      try {
        thunkAPI.dispatch(startAPILoading());
        const existingUser = await getUserInfo();
        thunkAPI.dispatch(updateSelf(existingUser));
        return;
      } catch (error) {
        if (error instanceof APIError && error.status === 404) {
          Router.replace('/setup');
          return;
        }
        throw error;
      } finally {
        thunkAPI.dispatch(endAPILoading());
      }
    }
    thunkAPI.dispatch(updateSelf(userState.self));
  },
);

export const getSelf = createAsyncThunk('user/getSelf', async (_, thunkAPI) => {
  thunkAPI.dispatch(startAPILoading());
  try {
    const existingUser = await getUserInfo();
    thunkAPI.dispatch(updateSelf(existingUser));
  } catch {
    thunkAPI.dispatch(refreshToken());
  } finally {
    thunkAPI.dispatch(endAPILoading());
  }
});

export const signout = createAsyncThunk('user/signout', async (_, thunkAPI) => {
  thunkAPI.dispatch(startAPILoading());
  try {
    await authClient.signout();
    Router.replace('/signin');
  } finally {
    thunkAPI.dispatch(endAPILoading());
  }
});

export const refreshToken = createAsyncThunk(
  'user/refreshToken',
  async (_, thunkAPI) => {
    thunkAPI.dispatch(startAPILoading());
    try {
      const token = await authClient.refreshToken();
      if (token) {
        const existingUser = await getUserInfo();
        thunkAPI.dispatch(updateSelf(existingUser));
        return token;
      }
      thunkAPI.dispatch(signout());
      throw new Error('Failed to update refresh token');
    } finally {
      thunkAPI.dispatch(endAPILoading());
    }
  },
);

export const readNews = createAsyncThunk(
  'user/readNews',
  async (_, thunkAPI) => {
    // getAll news
    // calculate if there is unread
    const state = thunkAPI.getState() as ApplicationState;
    // update last read news time
    const updatingUser = { ...state.users.self };
    updatingUser.newsReadAt = dayjs(Date.now()).format();
    await updateUser(updatingUser);
    thunkAPI.dispatch(updateSelf(updatingUser));
  },
);

export const userPurchaseSubscription = createAsyncThunk(
  'user/purchaseSubscription',
  async (_, thunkAPI) => {
    thunkAPI.dispatch(startAPILoading());
    try {
      const updatedUser = await purchaseSubscription();
      thunkAPI.dispatch(
        showSuccessToast({
          title: 'サブスクリプションを購入しました',
          desc: 'すべてのコースを視聴することが出来ます。',
        }),
      );
      thunkAPI.dispatch(updateSelf(updatedUser));
    } finally {
      thunkAPI.dispatch(endAPILoading());
    }
  },
);

export const userUpdateSubscription = createAsyncThunk(
  'user/updateSubscription',
  async (input: { type: string }, thunkAPI) => {
    const {
      users: { self },
    } = thunkAPI.getState() as ApplicationState;
    thunkAPI.dispatch(startAPILoading());
    if (!subscriptionStateChanged(self.subscription, input.type)) {
      throw new Error('There is no change to the subscription');
    }
    try {
      let updatedUser;
      if (!self.subscription) {
        updatedUser = await purchaseSubscription();
        thunkAPI.dispatch(
          showSuccessToast({
            title: 'サブスクリプションを購入しました',
            desc: 'すべてのコースを視聴することが出来ます。',
          }),
        );
      } else if (subscribing(self.subscription)) {
        updatedUser = await cancelSubscription();
        thunkAPI.dispatch(
          showSuccessToast({
            title: 'サブスクリプションをキャンセルしました',
            desc: '期限までコースを視聴することが出来ます。',
          }),
        );
      } else {
        updatedUser = await updateSubscription();
        thunkAPI.dispatch(
          showSuccessToast({
            title: 'サブスクリプションを変更しました',
            desc: 'すべてのコースを視聴することが出来ます。',
          }),
        );
      }
      thunkAPI.dispatch(updateSelf(updatedUser));
      Router.replace('/dashboard/settings');
    } catch (error) {
      thunkAPI.dispatch(
        showErrorToast({
          desc: 'エラーが発生しました。時間をおいて再度お試しください',
        }),
      );

      throw error;
    } finally {
      thunkAPI.dispatch(endAPILoading());
    }
  },
);

export const closePurchaseFooter = createAsyncThunk(
  'user/closePurchaseFooter',
  async () => {
    return saveSubscriptionClosed();
  },
);

export const updateToken = createAction<string>('user/updateToken');

export const updateSelf = createAction<User>('user/updateSelf');

export const updateAuthUser = createAction<AuthUser>('user/updateAuthUser');

export const resetUserError = createAction('user/resetError');
