import { apiClient } from './api';
import { authClient } from './auth';
import { DisplayContent } from './content';
import { User } from './user';

export interface CreateForum {
  title: string;
  desc: string;
  contentIDs: number[];
  tags: string[];
}

export interface CreateComment {
  forumID: number;
  desc: string;
  contentIDs: number[];
}

export interface Comment extends CreateComment {
  id: number;
  createdAt: string;
  creator: User;
  contents: DisplayContent[];
}

export interface Forum extends CreateForum {
  id: number;
  createdAt: string;
  updatedAt: string;
  contents: DisplayContent[];
  comments: Comment[];
  creator: User;
  viewCount: number;
  numberOfComments: number;
}

export const createForum = async (data: CreateForum): Promise<Forum> => {
  const forum = await apiClient.post<Forum>('/forums', data);
  if (forum.comments) {
    return forum;
  }
  return {
    ...forum,
    comments: [],
  };
};

export const updateForum = async (data: Forum): Promise<Forum> => {
  return apiClient.put<Forum>(`/forums/${data.id}`, data);
};

export interface ForumQuery {
  sort?: string;
  query?: string;
  limit?: number;
  offset?: number;
}

export const getForums = async (data: ForumQuery): Promise<Forum[]> => {
  const forums = await apiClient.fetch<Forum[]>(
    '/forums',
    data as Record<string, string>,
  );
  return forums.map(forum => {
    if (forum.comments) {
      return forum;
    }
    return {
      ...forum,
      comments: [],
    };
  });
};

export interface RelatedForumQuery {
  offset: number;
  limit: number;
  type: 'forum' | 'comment';
}

export const getRelatedForums = async (
  input: RelatedForumQuery,
): Promise<Forum[]> => {
  const userID = authClient.authUser?.userID;
  if (!userID) {
    throw new Error('ログイン情報が見つかりません');
  }
  const key = input.type === 'forum' ? 'createdBy' : 'commentedBy';
  const query: Record<string, string> = {
    offset: String(input.offset),
    limit: String(input.limit),
    [key]: userID,
  };
  const forums = await apiClient.fetch<Forum[]>(`/forums`, query);
  return forums.map(forum => {
    if (forum.comments) {
      return forum;
    }
    return {
      ...forum,
      comments: [],
    };
  });
};

export const getForum = async (forumID: number): Promise<Forum> => {
  const forum = await apiClient.fetch<Forum>(`/forums/${forumID}`);
  const comments = await apiClient.fetch<Comment[]>(
    `/forums/${forumID}/comments`,
  );
  return {
    ...forum,
    comments,
  };
};

export const getComments = async (forumID: number): Promise<Comment[]> => {
  return apiClient.fetch<Comment[]>(`/forums/${forumID}/comments`);
};

export const createComment = async (data: CreateComment): Promise<Comment> => {
  return apiClient.post(`/forums/${data.forumID}/comments`, data);
};

export const updateComment = async (data: Comment): Promise<Comment> => {
  return apiClient.put(`/forums/${data.forumID}/comments/${data.id}`, data);
};
