import { createAsyncThunk } from '@reduxjs/toolkit';
import { endAPILoading, startAPILoading } from '../loading/action';
import {
  getTeacher,
  getTeachers,
  getTeachersByQuery,
} from '../../repositories/teacher';
import { getCourse } from '../../repositories/course';
import { courseSearched } from '../courses/action';
import Router from 'next/router';

export const teachersGet = createAsyncThunk(
  'teachers/getAll',
  async (input: { sort: string } | undefined, thunkAPI) => {
    thunkAPI.dispatch(startAPILoading());
    try {
      const teachers = await getTeachers(input?.sort);
      return teachers;
    } finally {
      thunkAPI.dispatch(endAPILoading());
    }
  },
);

export const teachersGetByQuery = createAsyncThunk(
  'teachers/getByQuery',
  async (input: { query: string }, thunkAPI) => {
    thunkAPI.dispatch(startAPILoading());
    try {
      const teachers = await getTeachersByQuery(input.query);
      return teachers;
    } finally {
      thunkAPI.dispatch(endAPILoading());
    }
  },
);

export const teacherGet = createAsyncThunk(
  'teachers/get',
  async (input: { id: string }, thunkAPI) => {
    thunkAPI.dispatch(startAPILoading());
    try {
      const teacher = await getTeacher(input.id);
      const courses = await Promise.all(
        teacher.courseIDs.map(id => getCourse(id)),
      );
      thunkAPI.dispatch(courseSearched(courses));
      return teacher;
    } catch (error: any) {
      if (error?.status === 404) {
        Router.replace('/404');
      }
      throw error;
    } finally {
      thunkAPI.dispatch(endAPILoading());
    }
  },
);
