import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
  getDefaultMiddleware,
} from '@reduxjs/toolkit';
import { authUserListener } from './users/actions';
import { usersReducer } from './users/reducer';
import { externalUsersReducer } from './external_users/reducer';
import { followsReducer } from './follows/reducer';
import { forumsReducer } from './forums/reducer';
import { notesReducer } from './notes/reducer';
import { courseQuestionsReducer } from './course_questions/reducer';
import { badgeReducer } from './badges/reducer';
import { courseReducer } from './courses/reducer';
import { apiClient } from '../repositories/api';
import { authClient } from '../repositories/auth';
import { loadingReducer } from './loading/reducer';
import { teachersReducer } from './teachers/reducer';
import { newsReducer } from './news/reducer';
import { notificationsReducer } from './notifications/reducer';
import { studentReducer } from './students/reducer';
import { toastReducer } from './toast/reducer';
import { forgeReducer } from './forge/reducer';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createRootReducer = () =>
  combineReducers({
    users: usersReducer,
    externalUsers: externalUsersReducer,
    follows: followsReducer,
    forums: forumsReducer,
    notes: notesReducer,
    courseQuestions: courseQuestionsReducer,
    courses: courseReducer,
    loading: loadingReducer,
    teachers: teachersReducer,
    students: studentReducer,
    news: newsReducer,
    notifications: notificationsReducer,
    toast: toastReducer,
    badges: badgeReducer,
    forge: forgeReducer,
  });

const middlewares = getDefaultMiddleware();

export const store = configureStore({
  reducer: createRootReducer(),
  // preloadedState: { users: { self: storedState } },
  middleware: middlewares,
  devTools: !['staging', 'production'].includes(
    process.env.NEXT_PUBLIC_ENV ?? '',
  ),
});

typeof window !== 'undefined' && apiClient.setAuthClient(authClient);
typeof window !== 'undefined' && authUserListener(store.dispatch);

export type ApplicationState = ReturnType<typeof store.getState>;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  ApplicationState,
  unknown,
  Action<string>
>;
