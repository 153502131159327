import { createAsyncThunk } from '@reduxjs/toolkit';
import { getNews } from '../../repositories/news';

export const newsGetAll = createAsyncThunk('news/getAll', async () => {
  // getAll news
  const news = await getNews();
  return {
    news,
  };
});
