import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  createFavorite,
  createNote,
  deleteFavorite,
  deleteNote,
  getMyTags,
  getNote,
  getNotes,
  NoteStatus,
  updateNote,
} from '../../repositories/note';
import { endAPILoading, startAPILoading } from '../loading/action';
import { showSuccessToast } from '../toast/action';
import Router from 'next/router';

export interface GetNoteInput {
  mine: boolean;
  noteID: number;
}

export interface GetNotesInput {
  mine: boolean;
}
export interface CreateNoteInput {
  title: string;
  desc: string;
  courseID: number;
  lectureID: number;
  status: NoteStatus;
}

export interface UpdateNoteInput {
  id: number;
  title: string;
  desc: string;
  courseID: number;
  lectureID: number;
  status: NoteStatus;
}
export const noteCancel = createAsyncThunk('notes/cancel', async () => {
  Router.push('/dashboard/mypage?tabIndex=1');
});

export const noteCreate = createAsyncThunk(
  'notes/create',
  async (input: CreateNoteInput, thunkAPI) => {
    thunkAPI.dispatch(startAPILoading());
    try {
      const note = await createNote({ ...input });
      Router.push('/dashboard/mypage?tabIndex=1');
      thunkAPI.dispatch(
        showSuccessToast({
          title: 'ノートを作成しました',
          desc: 'あなたのノートはマイページから確認できます。',
        }),
      );
      return note;
    } finally {
      thunkAPI.dispatch(endAPILoading());
    }
  },
);

export const noteEdit = createAsyncThunk(
  'notes/edit',
  async (input: UpdateNoteInput, thunkAPI) => {
    thunkAPI.dispatch(startAPILoading());
    try {
      const note = await updateNote({ ...input });
      Router.push(`/dashboard/mypage?tabIndex=1`);
      thunkAPI.dispatch(
        showSuccessToast({
          title: 'ノートの編集',
          desc: 'ノートの編集に成功しました。',
        }),
      );
      return note;
    } finally {
      thunkAPI.dispatch(endAPILoading());
    }
  },
);

export const noteDelete = createAsyncThunk(
  'notes/delete',
  async (noteID: number, thunkAPI) => {
    thunkAPI.dispatch(startAPILoading());
    try {
      await deleteNote(noteID);
      thunkAPI.dispatch(
        showSuccessToast({
          title: 'ノートの削除',
          desc: 'ノートの削除に成功しました。',
        }),
      );
    } finally {
      thunkAPI.dispatch(endAPILoading());
    }
  },
);

export const noteGetAll = createAsyncThunk(
  'notes/getAll',
  async (input: GetNotesInput, thunkAPI) => {
    thunkAPI.dispatch(startAPILoading());
    try {
      const notes = await getNotes(input);
      return notes;
    } finally {
      thunkAPI.dispatch(endAPILoading());
    }
  },
);

export const noteGet = createAsyncThunk(
  'notes/getNote',
  async (input: GetNoteInput, thunkAPI) => {
    thunkAPI.dispatch(startAPILoading());
    try {
      const note = await getNote(input);
      return note;
    } finally {
      thunkAPI.dispatch(endAPILoading());
    }
  },
);

export const myTagsGet = createAsyncThunk(
  'notes/getMyTags',
  async (_, thunkAPI) => {
    thunkAPI.dispatch(startAPILoading());
    try {
      const note = await getMyTags();
      return note;
    } finally {
      thunkAPI.dispatch(endAPILoading());
    }
  },
);

export const favoriteCreate = createAsyncThunk(
  'notes/createFavorite',
  async (noteID: number, thunkAPI) => {
    thunkAPI.dispatch(startAPILoading());
    try {
      const note = await createFavorite(noteID);
      return note;
    } finally {
      thunkAPI.dispatch(endAPILoading());
    }
  },
);

export const favoriteDelete = createAsyncThunk(
  'notes/deleteFavorite',
  async (noteID: number, thunkAPI) => {
    thunkAPI.dispatch(startAPILoading());
    try {
      const note = await deleteFavorite(noteID);
      return note;
    } finally {
      thunkAPI.dispatch(endAPILoading());
    }
  },
);
