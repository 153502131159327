import { apiClient } from './api';
import { authClient } from './auth';

export const BadgeMedal = {
  Bronze: 0,
  Silver: 1,
  Gold: 2,
} as const;
export type BadgeMedal = typeof BadgeMedal[keyof typeof BadgeMedal];

const BadgeType = {
  Login: 0,
  Usage: 1,
  Lecture: 2,
  Course: 3,
  Forum: 4,
  ForumComment: 5,
  Friend: 6,
  Note: 7,
  Like: 8,
  Liked: 9,
} as const;
export type BadgeType = typeof BadgeType[keyof typeof BadgeType];

export interface MasterBadge {
  id: number;
  type: BadgeType;
  medal: BadgeMedal;
  name: string;
  required: number;
  condition: string;
}

export interface UserBadge {
  userID: string;
  type: BadgeType;
  count: number;
  obtainedBadges: UserBadgeRecord[];
}

export interface UserBadgeRecord {
  id: number;
  userID: string;
  type: BadgeType;
  medal: BadgeMedal;
  createdAt: string;
}

export const getMasterBadges = async (): Promise<MasterBadge[]> => {
  return await apiClient.fetch<MasterBadge[]>(`/masters/badges`);
};

export const getUserBadges = async (params?: {
  userID?: string;
}): Promise<UserBadge[]> => {
  let userID = authClient.authUser?.userID;

  if (params?.userID) {
    userID = params.userID;
  }

  if (!userID) {
    throw new Error('ログイン情報が見つかりません');
  }
  return await apiClient.fetch<UserBadge[]>('/badges', { userID });
};
