import { createReducer } from '@reduxjs/toolkit';
import { News } from '../../repositories/news';
import { newsGetAll } from './action';

interface NewsState {
  news: News[];
}

const initialState: NewsState = {
  news: [],
};

export const newsReducer = createReducer(initialState, builder => {
  builder.addCase(newsGetAll.fulfilled, (state, action) => {
    state.news = action.payload.news;
  });
});
