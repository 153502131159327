import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { endAPILoading, startAPILoading } from '../loading/action';
import {
  updateStatus,
  getNotifications,
  getUnreadNotifications,
  NOTIFICATION_CATEGORY_KINDS,
} from '../../repositories/notification';
import {
  getNotificationSettings as callGetNotificationSettings,
  updateNotificationSettings as callUpdateNotificationSettings,
} from '@/repositories/notification-setting';

export const notificationRead = createAsyncThunk(
  'notification-status',
  async (input: { notificationIDs: number[] }, thunkAPI) => {
    thunkAPI.dispatch(startAPILoading());
    try {
      const notifications = await updateStatus({ ...input });
      return notifications;
    } finally {
      thunkAPI.dispatch(endAPILoading());
    }
  },
);
export const unreadNotificationRead = createAsyncThunk(
  'unread-notification-status',
  async (input: { notificationIDs: number[] }, thunkAPI) => {
    thunkAPI.dispatch(startAPILoading());
    try {
      const notifications = await updateStatus({ ...input });
      return notifications;
    } finally {
      thunkAPI.dispatch(endAPILoading());
    }
  },
);
export const notificationsGet = createAsyncThunk(
  'notifications/getAll',
  async (_, thunkAPI) => {
    thunkAPI.dispatch(startAPILoading());
    try {
      const notifications = await getNotifications();
      return notifications;
    } finally {
      thunkAPI.dispatch(endAPILoading());
    }
  },
);
export const unreadNotificationsGet = createAsyncThunk(
  'notifications/getUnreadAll',
  async (_, thunkAPI) => {
    thunkAPI.dispatch(startAPILoading());
    try {
      const notifications = await getUnreadNotifications();
      return notifications;
    } finally {
      thunkAPI.dispatch(endAPILoading());
    }
  },
);

export const getNotificationSettings = createAsyncThunk(
  'notifications/getNotificationSettings',
  async (_, thunkAPI) => {
    thunkAPI.dispatch(startAPILoading());
    try {
      return await callGetNotificationSettings();
    } finally {
      thunkAPI.dispatch(endAPILoading());
    }
  },
);

export const notificationsGetByCategory = createAsyncThunk(
  'notifications/getByCategory',
  async (input: { category: number }, thunkAPI) => {
    thunkAPI.dispatch(startAPILoading());
    try {
      const notifications = await getNotifications(input.category);
      return notifications;
    } finally {
      thunkAPI.dispatch(endAPILoading());
    }
  },
);
export const updateNotificationSettings = createAsyncThunk(
  'notifications/updateNotificationSettings',
  async (data: any, thunkAPI) => {
    thunkAPI.dispatch(startAPILoading());
    try {
      return await callUpdateNotificationSettings(data);
    } finally {
      thunkAPI.dispatch(endAPILoading());
    }
  },
);
export const notificationsUpdateListFilter = createAction<
  NOTIFICATION_CATEGORY_KINDS
>('notifications/updateFilter');
