import { initializeApp, getApp, getApps } from 'firebase/app';
import {
  Auth,
  getAuth,
  onAuthStateChanged,
  browserLocalPersistence,
  setPersistence,
  UserInfo,
  signInWithEmailLink as signInWithEmailLinkAPI,
  signInWithPopup as signInWithPopupAPI,
  linkWithPopup as linkWithPopupAPI,
  unlink as unlinkAPI,
  GoogleAuthProvider,
  TwitterAuthProvider,
  FacebookAuthProvider,
} from 'firebase/auth';
import { getFirebaseConfig } from '../utils/auth';
import { postWithoutToken } from './api';
import { EventEmitter } from 'events';
import {
  AUTH_STATE_SIGNED_IN,
  AUTH_STATE_SIGNED_OUT,
  AUTH_STATE_UNKNOWN,
} from '../constants/auth_states';

export interface AuthUser {
  idToken: string;
  userID: string;
  email: string;
  displayName: string;
}

const AUTH_USER_KEY = 'AUTH_USER_KEY';
const AUTH_LOGIN_EMAIL = 'AUTH_LOGIN_EMAIL';

export class AuthClient {
  private readonly _firebaseAuth: Auth;
  private _authUser: AuthUser | undefined;
  private _event: EventEmitter;
  private _authState = AUTH_STATE_UNKNOWN;
  private _providerData: (UserInfo | null)[] = [];

  constructor() {
    let fApp;
    if (getApps().length === 0) {
      fApp = initializeApp(getFirebaseConfig());
    } else {
      fApp = getApp();
    }
    this._firebaseAuth = getAuth(fApp);
    this._event = new EventEmitter();

    const storedInfoStr =
      typeof window !== 'undefined' && localStorage.getItem(AUTH_USER_KEY);
    this._authUser =
      storedInfoStr && storedInfoStr !== ''
        ? JSON.parse(storedInfoStr)
        : undefined;
  }

  public onAuthChange(fn: (user?: AuthUser) => void): void {
    onAuthStateChanged(this._firebaseAuth, user => {
      if (user) {
        user.getIdToken().then(idToken => {
          this._authState = AUTH_STATE_SIGNED_IN;
          const authUser = {
            idToken,
            displayName: user.displayName as string,
            email: user.email as string,
            userID: user.uid,
          };
          this._setAuthUser(authUser);
          fn(authUser);
        });
        this._providerData = user.providerData;
        return;
      }
      this._authState = AUTH_STATE_SIGNED_OUT;
      this._setAuthUser(undefined);
      fn();
    });
  }

  public onRefreshToken(fn: (token: string) => void): void {
    this._event.on('refreshToken', token => {
      fn(token);
    });
  }

  public get authState(): number {
    return this._authState;
  }

  public get authUser(): AuthUser | undefined {
    return this._authUser;
  }

  public get providerData(): (UserInfo | null)[] {
    return this._providerData;
  }

  public getToken(): string {
    if (!this._authUser) {
      throw new Error('ログイン情報がありません。');
    }
    return this._authUser.idToken;
  }

  public async refreshToken(): Promise<string> {
    if (!this._firebaseAuth.currentUser) {
      throw new Error('ログイン情報がないため、Tokenの更新に失敗しました.');
    }
    const newToken = await this._firebaseAuth.currentUser?.getIdToken();
    this._event.emit('refreshToken', newToken);
    this._setAuthUser({
      idToken: newToken,
      userID: this._firebaseAuth.currentUser.uid,
      email: this._firebaseAuth.currentUser.email as string,
      displayName: this._firebaseAuth.currentUser.displayName as string,
    });
    return newToken;
  }

  public async signout(): Promise<void> {
    await this._firebaseAuth.signOut();
  }

  async signinWithEmailLink(path: string): Promise<AuthUser> {
    await setPersistence(this._firebaseAuth, browserLocalPersistence);

    const email =
      typeof window !== 'undefined' && localStorage.getItem(AUTH_LOGIN_EMAIL);
    if (!email) {
      throw new Error('ログインコードを送信したEmail情報がありません。');
    }
    const res = await signInWithEmailLinkAPI(this._firebaseAuth, email, path);
    if (!res.user) {
      throw new Error('ユーザのログインに失敗しました。');
    }
    const idToken = await res.user.getIdToken();
    this._setAuthUser({
      idToken,
      userID: res.user.uid,
      email: res.user.email as string,
      displayName: res.user.displayName as string,
    });
    return this._authUser as AuthUser;
  }

  async signinWithEmail(email: string, path: string): Promise<void> {
    await postWithoutToken('/signin', { email, path });
    typeof window !== 'undefined' &&
      localStorage.setItem(AUTH_LOGIN_EMAIL, email);
  }

  async signinWithPopup(
    providerName: 'google' | 'twitter' | 'facebook',
  ): Promise<AuthUser> {
    const provider = {
      google: new GoogleAuthProvider(),
      twitter: new TwitterAuthProvider(),
      facebook: new FacebookAuthProvider(),
    }[providerName];
    const res = await signInWithPopupAPI(this._firebaseAuth, provider);
    if (!res.user) {
      throw new Error('ユーザのログインに失敗しました。');
    }
    const idToken = await res.user.getIdToken();
    this._setAuthUser({
      idToken,
      userID: res.user.uid,
      email: res.user.email as string,
      displayName: res.user.displayName as string,
    });
    return this._authUser as AuthUser;
  }

  async linkWithPopup(
    providerName: 'google' | 'twitter' | 'facebook',
  ): Promise<void> {
    const user = this._firebaseAuth.currentUser;
    if (!user) {
      throw new Error('ユーザが未ログインのため、連携に失敗しました。');
    }
    if (await this.isLinked(providerName)) {
      // すでにリンクされていない場合は何もしない
      return;
    }

    const provider = {
      google: new GoogleAuthProvider(),
      twitter: new TwitterAuthProvider(),
      facebook: new FacebookAuthProvider(),
    }[providerName];

    await linkWithPopupAPI(user, provider);
  }

  async unlinkWithPopup(
    providerName: 'google' | 'twitter' | 'facebook',
  ): Promise<void> {
    const user = this._firebaseAuth.currentUser;
    if (!user) {
      throw new Error('ユーザが未ログインのため、連携解除に失敗しました。');
    }
    if (await !this.isLinked(providerName)) {
      // リンクされていない場合は何もしない
      return;
    }

    const providerId = {
      google: 'google.com',
      twitter: 'twitter.com',
      facebook: 'facebook.com',
    }[providerName];

    await unlinkAPI(user, providerId);
  }

  async isLinked(
    providerName: 'google' | 'twitter' | 'facebook' | 'password',
  ): Promise<boolean> {
    const sleep = (msec: number) =>
      new Promise(resolve => setTimeout(resolve, msec));

    let counter = 10;
    // onAuthStateChanged が発火するまで最大10秒待つ
    while (counter--) {
      if (this.providerData.length > 0) {
        break;
      }
      await sleep(1000);
    }
    if (this.providerData.length < 1) {
      throw new Error('認証プロバイダーの取得に失敗しました。');
    }

    const found = !!this.providerData.find(
      data => data && data.providerId.startsWith(providerName),
    );

    return new Promise(resolve => resolve(found));
  }

  private _setAuthUser(authUser: AuthUser | undefined): void {
    this._authUser = authUser;
    const storedValue = authUser ? JSON.stringify(authUser) : '';
    typeof window !== 'undefined' &&
      localStorage.setItem(AUTH_USER_KEY, storedValue);
  }
}

export const authClient = new AuthClient();
