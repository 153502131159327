import jwtDecode, { JwtPayload } from 'jwt-decode';

interface UserInfo {
  self: {
    userID: string;
    idToken: string;
    firstName: string;
    lastName: string;
  };
}

export const tokenExpired = (token: string): boolean => {
  const decodedToken = jwtDecode<JwtPayload>(token);
  if (!decodedToken.exp) {
    return true;
  }
  const remaining = decodedToken.exp - Math.floor(Date.now() / 1000);
  return remaining < 0;
};

export const getToken = (state: UserInfo): string | undefined => {
  const isSignedIn = state.self.userID && state.self.idToken;
  if (isSignedIn && state.self.idToken !== '') {
    return state.self.idToken;
  }
  return undefined;
};

export const hasAuthInfo = (state: UserInfo): boolean =>
  !!(state.self.userID && state.self.idToken);

export const hasLoginInfo = (state: UserInfo): boolean =>
  !!(state.self.firstName && state.self.lastName);

declare let process: {
  env: {
    NEXT_PUBLIC_FIREBASE_API_KEY: string;
    NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN: string;
    NEXT_PUBLIC_FIREBASE_DATABASE_URL: string;
    NEXT_PUBLIC_FIREBASE_PROJECT_ID: string;
    NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET: string;
    NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID: string;
    NEXT_PUBLIC_FIREBASE_APP_ID: string;
    NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID: string;
  };
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getFirebaseConfig = () => ({
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
});
