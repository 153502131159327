import { apiClient } from './api';
import { authClient } from './auth';
import { APIError } from './errors';

export interface CourseStatus {
  id: number;
  userID: string;
  courseID: number;
  registeredAt: string;
  lectureProgresses: {
    id?: number;
    courseStatusID: number;
    lectureID: number;
    watchedTill: number;
    completed: boolean;
  }[];
  totalLectures: number;
}

export interface ForumStatus {
  userID: string;
  type: 'forum' | 'course';
  forumID: number;
  updatedAt: string;
}

export interface CourseQuizStatus {
  courseQuizID: number;
  courseID: number;
  numberOfCorrectAnswers: number;
  numberOfWrongAnswers: number;
  createdAt: string;
  pass: boolean;
  solutions: {
    questionID: number;
    answerID: number;
    userAnswerID: number;
    answerDesc: string;
  }[];
}

export interface CourseQuizAnswer {
  courseQuizID: number;
  answers: {
    questionID: number;
    answerID: number;
  }[];
}

export const getCourseStatus = async (): Promise<CourseStatus[]> => {
  const userID = authClient.authUser?.userID;

  if (!userID) {
    throw new Error('ログイン情報が見つかりません');
  }
  return apiClient.fetch<CourseStatus[]>(`/students/${userID}/courses`);
};

export const getCourseStatusByUserID = async (
  userID: string,
): Promise<CourseStatus[]> => {
  return apiClient.fetch<CourseStatus[]>('/course-status', { userID });
};

export const reportCourseStatus = async (
  courseID: number,
  courseStatus: CourseStatus,
): Promise<CourseStatus> => {
  const userID = authClient.authUser?.userID;
  if (!userID) {
    throw new Error('ログイン情報が見つかりません');
  }
  return apiClient.post<CourseStatus>(
    `/students/${userID}/courses/${courseID}/lectures`,
    courseStatus,
  );
};

export const registerCourseStatus = async (
  courseID: number,
): Promise<CourseStatus> => {
  const userID = authClient.authUser?.userID;
  if (!userID) {
    throw new Error('ログイン情報が見つかりません');
  }
  return apiClient.post<CourseStatus>(`/students/${userID}/courses`, {
    courseID,
  });
};

export const cancelCourseStatus = async (courseID: number): Promise<void> => {
  const userID = authClient.authUser?.userID;
  if (!userID) {
    throw new Error('ログイン情報が見つかりません');
  }
  return apiClient.delete<void>(`/students/${userID}/courses/${courseID}`);
};

export const getForumStatus = async (): Promise<ForumStatus[]> => {
  const userID = authClient.authUser?.userID;
  if (!userID) {
    throw new Error('ログイン情報が見つかりません');
  }
  return apiClient.fetch<ForumStatus[]>(`/students/${userID}/forums`);
};

export const reportForumStatus = async (
  forumID: number,
  type: 'course' | 'forum',
): Promise<ForumStatus> => {
  const userID = authClient.authUser?.userID;
  if (!userID) {
    throw new Error('ログイン情報が見つかりません');
  }
  return apiClient.post<ForumStatus>(`/students/${userID}/forums`, {
    forumID,
    type,
  });
};

export const getCourseQuizStatus = async (
  courseID: number,
): Promise<CourseQuizStatus | undefined> => {
  const userID = authClient.authUser?.userID;
  if (!userID) {
    throw new Error('ログイン情報が見つかりません');
  }
  try {
    const status = await apiClient.fetch<CourseQuizStatus[]>(
      `/students/${userID}/quizzes/${courseID}`,
    );
    return status[0];
  } catch (error) {
    if (error instanceof APIError && error.status === 404) {
      return undefined;
    }
    throw error;
  }
};

export const submitCourseQuizStatus = async (
  courseID: number,
  data: CourseQuizAnswer,
): Promise<CourseQuizStatus> => {
  const userID = authClient.authUser?.userID;
  if (!userID) {
    throw new Error('ログイン情報が見つかりません');
  }
  return apiClient.post<CourseQuizStatus>(
    `/students/${userID}/quizzes/${courseID}`,
    { courseQuizAnswer: data },
  );
};
