import axios from 'axios';
import { EventEmitter } from 'events';
import { AuthClient } from './auth';
import { APIError } from './errors';

declare let process: {
  env: {
    NEXT_PUBLIC_API_URL: string;
  };
};

export const getBaseURL = (): string => process.env.NEXT_PUBLIC_API_URL;

export const getHeader = (token?: string): Record<string, string> => {
  const header = {
    'Content-Type': 'application/json',
  };
  return token
    ? {
        ...header,
        Authorization: `Bearer ${token}`,
      }
    : header;
};

export const getURL = (
  path: string,
  query?: Record<string, string>,
): string => {
  const url = `${getBaseURL()}/api/v1${path}`;
  if (!query) {
    return url;
  }
  const queryString = new URLSearchParams(query).toString();
  return `${url}?${queryString}`;
};

export const postWithoutToken = async <T>(
  path: string,
  content: unknown,
): Promise<T> => {
  try {
    const res = await axios.post(getURL(path), content);
    return res.data.data;
  } catch (error: any) {
    if (error?.response?.data?.error) {
      const apiError = new APIError(
        error.response.data.error.message,
        error.response.status,
        error.response.data.error.code,
      );
      throw apiError;
    }
    throw new APIError(error.message, 500, 'unknown_error');
  }
};

const isAuthErr = (error: any): boolean =>
  error?.response?.data?.error.code === 'err_invalid_auth';

const API_ERROR_KEY = 'apiError';

class APIClient {
  private _event = new EventEmitter();
  private _authClient!: AuthClient;

  public onAPIError(fn: (err: APIError) => void) {
    this._event.on(API_ERROR_KEY, fn);
  }

  public setAuthClient(authClient: AuthClient) {
    this._authClient = authClient;
  }

  public async fetch<T>(
    path: string,
    query?: Record<string, string>,
    refreshToken?: boolean,
  ): Promise<T> {
    try {
      const token = await this._getToken(refreshToken);
      const res = await axios.get<{ data: T }>(getURL(path, query), {
        headers: getHeader(token),
      });
      return res.data.data;
    } catch (error: any) {
      if (isAuthErr(error) && !refreshToken) {
        return this.fetch(path, query, true);
      }
      return this._handleError(error);
    }
  }

  public async fetchWithoutToken<T>(
    path: string,
    query?: Record<string, string>,
  ): Promise<T> {
    try {
      const res = await axios.get<{ data: T }>(getURL(path, query));
      return res.data.data;
    } catch (error: any) {
      return this._handleError(error);
    }
  }

  public async postWithoutToken<T>(path: string, content: unknown): Promise<T> {
    try {
      const res = await axios.post(getURL(path), content);
      return res.data.data;
    } catch (error: any) {
      return this._handleError(error);
    }
  }

  public async post<T>(
    path: string,
    content: unknown,
    refreshToken?: boolean,
  ): Promise<T> {
    try {
      const token = await this._getToken(refreshToken);
      const res = await axios.post(getURL(path), content, {
        headers: getHeader(token),
      });
      return res.data.data;
    } catch (error: any) {
      if (isAuthErr(error) && !refreshToken) {
        return this.post(path, content, true);
      }
      return this._handleError(error);
    }
  }

  public async put<T>(
    path: string,
    content: unknown,
    refreshToken?: boolean,
  ): Promise<T> {
    try {
      const token = await this._getToken(refreshToken);
      const res = await axios.put(getURL(path), content, {
        headers: getHeader(token),
      });
      return res.data.data;
    } catch (error: any) {
      if (isAuthErr(error) && !refreshToken) {
        return this.put(path, content, true);
      }
      return this._handleError(error);
    }
  }

  public async patch<T>(
    path: string,
    content: unknown,
    refreshToken?: boolean,
  ): Promise<T> {
    try {
      const token = await this._getToken(refreshToken);
      const res = await axios.patch(getURL(path), content, {
        headers: getHeader(token),
      });
      return res.data.data;
    } catch (error: any) {
      if (isAuthErr(error) && !refreshToken) {
        return this.patch(path, content, true);
      }
      return this._handleError(error);
    }
  }

  public async delete<T>(path: string, refreshToken?: boolean): Promise<T> {
    try {
      const token = await this._getToken(refreshToken);
      const res = await axios.delete(getURL(path), {
        headers: getHeader(token),
      });
      return res.data.data;
    } catch (error: any) {
      if (isAuthErr(error) && !refreshToken) {
        return this.delete(path, true);
      }
      return this._handleError(error);
    }
  }

  public async postFile<T = unknown>(
    path: string,
    file: File,
    refreshToken?: boolean,
  ): Promise<T> {
    const data = new FormData();
    data.append('file', file);
    data.append('filename', file.name);
    try {
      const token = await this._getToken(refreshToken);
      const res = await axios.post(getURL(path), data, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      return res.data.data;
    } catch (error: any) {
      if (isAuthErr(error) && !refreshToken) {
        return this.postFile(path, file, true);
      }
      return this._handleError(error);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _handleError(error: any): never {
    if (error?.response?.data?.error) {
      const apiError = new APIError(
        error.response.data.error.message,
        error.response.status,
        error.response.data.error.code,
      );
      this._event.emit(API_ERROR_KEY, apiError);
      throw apiError;
    }
    const apiError = new APIError(error.message, 500, 'unknown_error');
    this._event.emit(API_ERROR_KEY, apiError);
    throw apiError;
  }

  private async _getToken(refresh?: boolean): Promise<string> {
    if (refresh) {
      return this._authClient.refreshToken();
    }
    return this._authClient.getToken();
  }
}

export const apiClient = new APIClient();
