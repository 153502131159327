import { getForgeToken } from '@/repositories/forge';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const forgeTokenGet = createAsyncThunk('forge/getToken', async () => {
  // get forge token
  const token = await getForgeToken();
  return {
    token,
  };
});
