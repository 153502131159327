import { createReducer } from '@reduxjs/toolkit';
import { CourseQuestion } from '../../repositories/course_questions';
import {
  courseQuestionsCommentCreate,
  courseQuestionsCreate,
  courseQuestionsGet,
  courseQuestionsGetAll,
  courseQuestionsGetRelated,
  courseQuestionsGetRelatedComments,
  courseQuestionsGetRelatedLoadMore,
} from './action';

interface CourseQuestionState {
  courseQuestions: CourseQuestion[];
  forumDetailUpdating: boolean;
  loadingMyForum: boolean;
  forumsLoading: boolean;
}

const initialState: CourseQuestionState = {
  courseQuestions: [],
  loadingMyForum: false,
  forumDetailUpdating: false,
  forumsLoading: false,
};

export const courseQuestionsReducer = createReducer(initialState, builder => {
  builder
    .addCase(courseQuestionsGetRelated.pending, state => {
      state.courseQuestions = [];
      state.loadingMyForum = true;
    })
    .addCase(courseQuestionsGetRelated.rejected, state => {
      state.loadingMyForum = false;
    })
    .addCase(courseQuestionsCreate.fulfilled, (state, action) => {
      state.courseQuestions = [...state.courseQuestions, action.payload];
    })
    .addCase(courseQuestionsGetRelated.fulfilled, (state, action) => {
      state.courseQuestions = action.payload;
      state.loadingMyForum = false;
    })
    .addCase(courseQuestionsGetRelatedLoadMore.fulfilled, (state, action) => {
      for (const forum of action.payload) {
        if (state.courseQuestions.findIndex(f => f.id === forum.id) < 0) {
          state.courseQuestions.push(forum);
        }
      }
    })
    .addCase(courseQuestionsGetAll.fulfilled, (state, action) => {
      state.courseQuestions = action.payload.reduce((prev, current) => {
        const preExistIndex = prev.findIndex(f => f.id === current.id);
        if (preExistIndex > -1) {
          prev[preExistIndex] = current;
          return prev;
        }
        prev.push(current);
        return prev;
      }, state.courseQuestions);
    })
    .addCase(courseQuestionsGet.fulfilled, (state, action) => {
      const preExistIndex = state.courseQuestions.findIndex(
        f => f.id === action.payload.id,
      );
      if (preExistIndex > -1) {
        state.courseQuestions[preExistIndex] = action.payload;
        return;
      }
      state.courseQuestions.push(action.payload);
    })
    .addCase(courseQuestionsGetRelatedComments.fulfilled, (state, action) => {
      if (!action.payload.length) {
        return;
      }
      const forumIndex = state.courseQuestions.findIndex(
        forum => forum.id === action.payload[0].forumID,
      );
      if (forumIndex < 0) {
        throw new Error(
          `Forum id ${action.payload[0].forumID} does not exist in related forums`,
        );
      }
      state.courseQuestions[forumIndex].comments = action.payload;
    })
    .addCase(courseQuestionsCommentCreate.fulfilled, (state, action) => {
      const forumIndex = state.courseQuestions.findIndex(
        f => f.id === action.payload.forumID,
      );
      if (forumIndex > -1) {
        state.courseQuestions[forumIndex].comments.push(action.payload);
      }
    });
});
