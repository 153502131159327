import { createAsyncThunk } from '@reduxjs/toolkit';
import { uploadFile } from '../../repositories/content';
import {
  createCourseQuestion,
  createCourseQuestionComment,
  getCourseQuestion,
  getCourseQuestionComments,
  getCourseQuestions,
  getRelatedCourseQuestions,
  RelatedCourseQuestionQuery,
} from '../../repositories/course_questions';
import { endAPILoading, startAPILoading } from '../loading/action';
import { studentsReportForumStatus } from '../students/action';

export interface CreateCourseQuestionInput {
  title: string;
  desc: string;
  files: File[];
  lectureID?: number;
  courseID: number;
  tags: string[];
}

export interface UpdateCourseQuestionInput {
  id: number;
  courseID: number;
  deletedContentIDs: number[];
  createdFiles: File[];
  title: string;
  desc: string;
  tags: string[];
}

export const courseQuestionsGetRelated = createAsyncThunk(
  'course-questions/getRelatedQuestions',
  async (input: RelatedCourseQuestionQuery, thunkAPI) => {
    thunkAPI.dispatch(startAPILoading());
    try {
      const forums = await getRelatedCourseQuestions(input);
      return forums;
    } finally {
      thunkAPI.dispatch(endAPILoading());
    }
  },
);

export const courseQuestionsGetRelatedLoadMore = createAsyncThunk(
  'course-questions/getRelatedQuestionsLoadMore',
  async (input: RelatedCourseQuestionQuery, thunkAPI) => {
    thunkAPI.dispatch(startAPILoading());
    try {
      const forums = await getRelatedCourseQuestions(input);
      return forums;
    } finally {
      thunkAPI.dispatch(endAPILoading());
    }
  },
);

export const courseQuestionsGetRelatedComments = createAsyncThunk(
  'course-questions/getRelatedComments',
  async (input: { forumID: number }, thunkAPI) => {
    thunkAPI.dispatch(startAPILoading());
    try {
      thunkAPI.dispatch(
        studentsReportForumStatus({ forumID: input.forumID, type: 'course' }),
      );
      return getCourseQuestionComments(input.forumID);
    } finally {
      thunkAPI.dispatch(endAPILoading());
    }
  },
);

export const courseQuestionsGetAll = createAsyncThunk(
  'course-questions/getAll',
  async (
    input: { id: number; data?: { sort?: string; query?: string } },
    thunkAPI,
  ) => {
    thunkAPI.dispatch(startAPILoading());
    try {
      const forums = await getCourseQuestions(input.id, input.data);
      return forums;
    } finally {
      thunkAPI.dispatch(endAPILoading());
    }
  },
);

export const courseQuestionsCreate = createAsyncThunk(
  'course-questions/create',
  async (input: CreateCourseQuestionInput, thunkAPI) => {
    thunkAPI.dispatch(startAPILoading());
    try {
      let contentIDs = [] as number[];
      if (input.files) {
        const uploadedFiles = await Promise.all(
          input.files.map(file => uploadFile(file)),
        );
        contentIDs = uploadedFiles.map(content => content.id);
      }
      const forum = await createCourseQuestion({
        ...input,
        contentIDs: contentIDs,
      });
      thunkAPI.dispatch(
        studentsReportForumStatus({ forumID: forum.id, type: 'course' }),
      );
      return forum;
    } finally {
      thunkAPI.dispatch(endAPILoading());
    }
  },
);

export interface CreateCommentInput {
  forumID: number;
  desc: string;
  files?: File[];
}

export const courseQuestionsCommentCreate = createAsyncThunk(
  'course-questions/create/comment',
  async (input: CreateCommentInput, thunkAPI) => {
    thunkAPI.dispatch(startAPILoading());
    try {
      let contentIDs: number[] = [];
      if (input.files) {
        const uploadedFiles = await Promise.all(
          input.files.map(file => uploadFile(file)),
        );
        contentIDs = uploadedFiles.map(content => content.id);
      }
      const comment = await createCourseQuestionComment({
        ...input,
        contentIDs,
      });
      thunkAPI.dispatch(
        studentsReportForumStatus({ forumID: input.forumID, type: 'course' }),
      );
      return comment;
    } finally {
      thunkAPI.dispatch(endAPILoading());
    }
  },
);

export const courseQuestionsGet = createAsyncThunk(
  'course-questions/get',
  async (input: { id: number }, thunkAPI) => {
    thunkAPI.dispatch(startAPILoading());
    try {
      const forum = await getCourseQuestion(input.id);
      thunkAPI.dispatch(
        studentsReportForumStatus({ forumID: forum.id, type: 'course' }),
      );
      return forum;
    } finally {
      thunkAPI.dispatch(endAPILoading());
    }
  },
);
