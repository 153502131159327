import { apiClient } from './api';
import { DisplayContent } from './content';
import { APIError } from './errors';

export interface Curriculum {
  id: number;
  title: string;
  desc: string;
  startDate: string;
  endDate: string;
  courseIDs: number[];
}

export const COURSE_TYPE = {
  NORMAL: 0,
  LIVE: 1,
  PERMANENT: 2,
} as const;

export type CourseType = typeof COURSE_TYPE[keyof typeof COURSE_TYPE];

export interface Course {
  id: number;
  curriculumID: number;
  type: CourseType;
  title: string;
  desc: string;
  categoryID: number;
  softwares: string[];
  tags: string[];
  category: string;
  thumbnailID: number;
  enrolledStudentsCount: number;
  numberOfLectures: number;
  expectedLectures: number;
  teacher: {
    lastName: string;
    firstName: string;
    avatorID: number;
    avatorURL: string;
    userID: string;
  };
  inCurrentQuarter: boolean;
  thumbnail: DisplayContent;
  isEnrolled?: boolean;
  createdAt: string;
  releasedAt: string;
}

export interface CourseWithLectures extends Course {
  lectures: Lecture[];
}

export interface CourseCategory {
  id: number;
  name: string;
}

export interface Lecture {
  id: number;
  courseID: number;
  title: string;
  desc: string;
  releasedAt: string;
  length: number;
  video: DisplayContent;
  thumbnail: DisplayContent;
  slide: DisplayContent | null;
  materials: DisplayContent[];
  completed: boolean;
  chapters: Chapter[];
  forgeUrn?: string;
}
export interface Chapter {
  id: number;
  lectureID: number;
  title: string;
  startTime: number;
}
export interface CourseQuiz {
  id: number;
  courseID: number;
  questions: {
    id: number;
    courseQuizID: number;
    title: string;
    desc: string;
    order: number;
    answers: CourseQuizAnswer[];
    contents: CourseQuizContent[];
  }[];
  createdAt: string;
}
export interface CourseQuizAnswer {
  id: number;
  courseQuestionID: number;
  desc: string;
  order: number;
  contentID: number;
  content: {
    id: number;
    name: string;
    url: string;
  };
}
export interface CourseQuizContent {
  id: number;
  name: string;
  url: string;
}
export const COURSE_ORDER_LATEST = 'latest';
export const COURSE_ORDER_SUBSCRIBERS = 'subscribers';
export type COURSE_SORT_KINDS =
  | typeof COURSE_ORDER_LATEST
  | typeof COURSE_ORDER_SUBSCRIBERS;

export const getCourseCategories = async (): Promise<CourseCategory[]> => {
  return apiClient.fetchWithoutToken<CourseCategory[]>('/course-categories');
};

export const findCourses = async (
  query: string,
  categoryID?: number,
  sort?: string,
  limit = 100,
  offset = 0,
): Promise<Course[]> => {
  const urlQuery: Record<string, string> = {
    query,
    sort: sort ?? COURSE_ORDER_LATEST,
    limit: String(limit),
    offset: String(offset),
  };
  if (categoryID !== undefined) {
    urlQuery['categoryID'] = String(categoryID);
  }
  return apiClient.fetchWithoutToken<Course[]>('/courses', urlQuery);
};

export const getQuarterCourses = async (
  current?: boolean,
  sort?: string,
  limit = 100,
  offset = 0,
): Promise<Course[]> => {
  return apiClient.fetchWithoutToken<Course[]>('/courses', {
    current: String(current ?? false),
    sort: sort ?? COURSE_ORDER_LATEST,
    limit: String(limit),
    offset: String(offset),
  });
};

export const getCurriculums = async (): Promise<Curriculum[]> => {
  return apiClient.fetchWithoutToken<Curriculum[]>(`/curriculums`);
};

export const getCourse = async (id: number): Promise<Course> => {
  return apiClient.fetchWithoutToken<Course>(`/courses/${id}`);
};

export const getCourseWithoutToken = async (id: number): Promise<Course> => {
  return apiClient.fetchWithoutToken<Course>(`/courses/${id}`);
};

export const getLectures = async (courseID: number): Promise<Lecture[]> => {
  return apiClient.fetch<Lecture[]>(`/courses/${courseID}/lectures`);
};

export const getLecturesWithoutToken = async (
  courseID: number,
): Promise<Lecture[]> => {
  return apiClient.fetchWithoutToken<Lecture[]>(
    `/courses/${courseID}/lectures`,
  );
};

export const getCourseQuiz = async (
  courseID: number,
): Promise<CourseQuiz | undefined> => {
  try {
    const quiz = await apiClient.fetch<CourseQuiz>(
      `/courses/${courseID}/quizzes`,
    );
    return quiz;
  } catch (error) {
    if (error instanceof APIError && error.status === 404) {
      return undefined;
    }
    throw error;
  }
};
