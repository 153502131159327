import { createAsyncThunk } from '@reduxjs/toolkit';
import { getMasterBadges, getUserBadges } from '@/repositories/badge';
import { endAPILoading, startAPILoading } from '@/store/loading/action';

export const masterBadgesGetAll = createAsyncThunk(
  'master-badges/getAll',
  async (_, thunkAPI) => {
    thunkAPI.dispatch(startAPILoading());
    try {
      return await getMasterBadges();
    } finally {
      thunkAPI.dispatch(endAPILoading());
    }
  },
);

export const userBadgesGetAll = createAsyncThunk(
  'user-badges/getAll',
  async (_, thunkAPI) => {
    thunkAPI.dispatch(startAPILoading());
    try {
      return await getUserBadges();
    } finally {
      thunkAPI.dispatch(endAPILoading());
    }
  },
);
