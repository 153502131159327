import { createReducer } from '@reduxjs/toolkit';
import { MasterBadge, UserBadge } from '@/repositories/badge';
import { masterBadgesGetAll, userBadgesGetAll } from './action';

const initialState = {
  masterBadges: [] as MasterBadge[],
  userBadges: [] as UserBadge[],
};

export const badgeReducer = createReducer(initialState, builder => {
  builder
    .addCase(masterBadgesGetAll.pending, state => {
      state.masterBadges = [];
    })
    .addCase(masterBadgesGetAll.fulfilled, (state, action) => {
      state.masterBadges = action.payload;
    })
    .addCase(userBadgesGetAll.pending, state => {
      state.userBadges = [];
    })
    .addCase(userBadgesGetAll.fulfilled, (state, action) => {
      state.userBadges = action.payload;
    });
});
