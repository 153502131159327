import { createReducer } from '@reduxjs/toolkit';
import { dayjs } from '@/utils/time';
import {
  AUTH_STATE_SIGNED_IN,
  AUTH_STATE_SIGNED_OUT,
  AUTH_STATE_UNKNOWN,
  REGISTER_STATE_NOT_REGISTERED,
  REGISTER_STATE_REGISTERED,
  REGISTER_STATE_UNKNOWN,
} from '../../constants/auth_states';
import { getError } from '../../repositories/errors';
import { ExtendedUser } from '../../repositories/user';
import {
  resetUserError,
  signin,
  updateAuthUser,
  signinWithEmail,
  updateSelf,
  signout,
  updateToken,
  refreshToken,
  userPurchaseSubscription,
  closePurchaseFooter,
} from './actions';

interface UserState {
  login: {
    authorized: number;
    registered: number;
  };
  self: ExtendedUser & { idToken: string };
  error:
    | {
        message: string;
        code: string;
      }
    | undefined;
  purchasing: boolean;
}

const initialState: UserState = {
  login: {
    authorized: AUTH_STATE_UNKNOWN,
    registered: REGISTER_STATE_UNKNOWN,
  },
  self: {
    userID: '',
    email: '',
    idToken: '',
    firstName: '',
    lastName: '',
    organization: '',
    desc: '',
    linkFacebook: '',
    linkTwitter: '',
    linkInstagram: '',
    newsReadAt: dayjs(0).format(),
    notification: {},
  },
  error: undefined,
  purchasing: false,
};

export const usersReducer = createReducer(initialState, builder => {
  builder
    .addCase(updateAuthUser, (state, action) => {
      state.self.userID = action.payload.userID;
      state.self.idToken = action.payload.idToken;
      state.self.email = action.payload.email;
      state.login.authorized = AUTH_STATE_SIGNED_IN;
    })
    .addCase(updateSelf, (state, action) => {
      state.login.registered = REGISTER_STATE_REGISTERED;
      state.self = {
        ...state.self,
        ...action.payload,
      };
    })
    .addCase(signinWithEmail.rejected, (state, action) => {
      state.error = getError(action.error.code);
    })
    .addCase(refreshToken.fulfilled, (state, action) => {
      state.self = {
        ...state.self,
        idToken: action.payload,
      };
    })
    .addCase(resetUserError, state => {
      state.error = undefined;
    })
    .addCase(updateToken, (state, action) => {
      state.self.idToken = action.payload;
    })
    .addCase(signout.fulfilled, state => {
      state.login.authorized = AUTH_STATE_SIGNED_OUT;
      state.login.registered = REGISTER_STATE_NOT_REGISTERED;
      state.self = {
        ...initialState.self,
      };
    })
    .addCase(signin.pending, (state, action) => {
      state.self.email = action.meta.arg.email;
    })
    .addCase(signin.rejected, (state, action) => {
      state.login.authorized = AUTH_STATE_SIGNED_OUT;
      state.error = getError(action.error.code);
    })
    .addCase(userPurchaseSubscription.pending, state => {
      state.purchasing = true;
    })
    .addCase(userPurchaseSubscription.fulfilled, state => {
      state.purchasing = false;
    })
    .addCase(userPurchaseSubscription.rejected, state => {
      state.purchasing = false;
    })
    .addCase(closePurchaseFooter.fulfilled, (state, action) => {
      state.self.notification.subscriptionHiddenUntil =
        action.payload.closedUntil;
    });
});
