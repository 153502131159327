import { createReducer } from '@reduxjs/toolkit';
import { endAPILoading, startAPILoading } from './action';

interface LoadingState {
  loading: boolean;
}

const initialState: LoadingState = {
  loading: false,
};

export const loadingReducer = createReducer(initialState, builder => {
  builder
    .addCase(startAPILoading, state => {
      state.loading = true;
    })
    .addCase(endAPILoading, state => {
      state.loading = false;
    });
});
