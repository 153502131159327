import { createReducer } from '@reduxjs/toolkit';
import { Forum } from '../../repositories/forum';
import {
  commentCreate,
  commentUpdate,
  forumCreate,
  forumsGet,
  forumsGetAll,
  forumsGetRelatedComments,
  forumsLoadMore,
  forumsGetRelatedTopics,
  forumsGetRelatedTopicsLoadMore,
  forumUpdate,
  forumsUpdateListSort,
} from './action';

interface ForumState {
  forums: Forum[];
  relatedForums: Forum[];
  forumDetailUpdating: boolean;
  loadingMyForum: boolean;
  forumsLoading: boolean;
  listSortKey: 'latest' | 'views';
}

const initialState: ForumState = {
  forums: [],
  relatedForums: [],
  loadingMyForum: false,
  forumDetailUpdating: false,
  forumsLoading: false,
  listSortKey: 'latest',
};

export const forumsReducer = createReducer(initialState, builder => {
  builder
    .addCase(forumUpdate.fulfilled, (state, action) => {
      state.forumDetailUpdating = false;
      const index = state.forums.findIndex(f => f.id === action.payload.id);
      state.forums[index] = {
        ...action.payload,
        comments: state.forums[index].comments,
      };
    })
    .addCase(forumUpdate.pending, state => {
      state.forumDetailUpdating = true;
    })
    .addCase(forumUpdate.rejected, state => {
      state.forumDetailUpdating = false;
    })
    .addCase(forumCreate.fulfilled, (state, action) => {
      state.forums = [...state.forums, action.payload];
    })
    .addCase(forumsGetAll.pending, state => {
      state.forums = [];
      state.forumsLoading = true;
    })
    .addCase(forumsGetAll.rejected, state => {
      state.forumsLoading = false;
    })
    .addCase(forumsGetAll.fulfilled, (state, action) => {
      state.forums = action.payload;
      state.forumsLoading = false;
    })
    .addCase(forumsLoadMore.fulfilled, (state, action) => {
      state.forums.push(...action.payload);
    })
    .addCase(forumsGetRelatedTopics.pending, state => {
      state.relatedForums = [];
      state.loadingMyForum = true;
    })
    .addCase(forumsGetRelatedTopics.rejected, state => {
      state.loadingMyForum = false;
    })
    .addCase(forumsGetRelatedTopics.fulfilled, (state, action) => {
      state.relatedForums = action.payload;
      state.loadingMyForum = false;
    })
    .addCase(forumsGetRelatedTopicsLoadMore.fulfilled, (state, action) => {
      state.relatedForums.push(...action.payload);
    })
    .addCase(forumsGet.fulfilled, (state, action) => {
      const preExistIndex = state.forums.findIndex(
        f => f.id === action.payload.id,
      );
      if (preExistIndex > -1) {
        state.forums[preExistIndex] = action.payload;
        return;
      }
      state.forums.push(action.payload);
    })
    .addCase(forumsGetRelatedComments.fulfilled, (state, action) => {
      if (!action.payload.length) {
        return;
      }
      const forumIndex = state.relatedForums.findIndex(
        forum => forum.id === action.payload[0].forumID,
      );
      if (forumIndex < 0) {
        throw new Error(
          `Forum id ${action.payload[0].forumID} does not exist in related forums`,
        );
      }
      state.relatedForums[forumIndex].comments = action.payload;
    })
    .addCase(commentCreate.fulfilled, (state, action) => {
      const preExistIndex = state.forums.findIndex(
        f => f.id === action.payload.forumID,
      );
      if (preExistIndex > -1) {
        state.forums[preExistIndex].comments.push(action.payload);
      }
      const preExistRelatedIndex = state.relatedForums.findIndex(
        f => f.id === action.payload.forumID,
      );
      if (preExistRelatedIndex > -1) {
        state.relatedForums[preExistRelatedIndex].comments.push(action.payload);
      }
    })
    .addCase(commentUpdate.pending, state => {
      state.forumDetailUpdating = true;
    })
    .addCase(commentUpdate.fulfilled, (state, action) => {
      state.forumDetailUpdating = false;
      const preExistForumIndex = state.forums.findIndex(
        f => f.id === action.payload.forumID,
      );
      if (preExistForumIndex < 0) {
        throw new Error('Comment should be updated only for existing forums');
      }
      const preExistingCommentIndex = state.forums[
        preExistForumIndex
      ].comments.findIndex(c => c.id === action.payload.id);
      if (preExistingCommentIndex < 0) {
        throw new Error('Comment should be updated only for existing comment');
      }
      state.forums[preExistForumIndex].comments[preExistingCommentIndex] =
        action.payload;
    })
    .addCase(commentUpdate.rejected, state => {
      state.forumDetailUpdating = false;
    })
    .addCase(forumsUpdateListSort, (state, action) => {
      state.listSortKey = action.payload;
    });
});
